import {GrpcGoogleAuthServicePromiseClient} from "proto/auth_grpc_web_pb";
import {AuthObj, IGoogleOAuthTokenResponse, JWTToken} from "model/auth";
import {
	ActionType,
	GrpcError,
	InternalErrorTypes,
	isError,
	IUIError,
	LogErrorToProvider,
	NewUIErrorV2,
	UIErrorV2
} from "service/cartaError";
import {
	FederatedProviderDTO,
	GoogleOAuthTokenDTO,
	HeartbeatRequest,
	LoginRequest,
	LoginResponse,
	LogoutRequest,
	SignUpProfileDTO,
	SignUpRequest,
	SignUpResponse
} from "proto/auth_pb";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {Err, Ok, Result} from "utils/result";
import {EntityKind} from "model/BaseModel";
import {User} from "model/user";
import {
	getLastHeartBeatTimeFromLocalStorage,
	removeAuthInfoFromLocalStorage,
	retrieveUserFromLocalStorage,
	storeAuthObjInLocalStorage,
	storeHeartBeatTimeInLocalStorage
} from "service/AuthService";
import {UserStore} from "stores/UserStore";
import {PricingSessionDto} from "proto/stripe_pb";
import grpcWeb from "grpc-web";
import {ErrorStatusDTO} from "proto/utils_pb";
import {PricingSession} from "pages/billing/pricing";
import {
	ALLOWED_EMAILS,
	CARTA_PROXY_URL,
	ENVIRONMENT,
	GRPC_CARTA_ERROR_HEADER,
	LOCAL_STORAGE_ENTITLEMENTS,
	LOCAL_STORAGE_SUBSCRIPTION_PRODUCT
} from "consts";
import {unifiedInterceptor} from "../utils/utils";

const authClient = new GrpcGoogleAuthServicePromiseClient(
	CARTA_PROXY_URL!,
	null,
	{
		'withCredentials': true,
		'unaryInterceptors': [unifiedInterceptor]
	}
);

export class AuthenticationStore {
	// @observable
	authenticationState: AuthenticationState = {
		state: AuthenticationStateEnum.LoggedOff,
		payload: null
	}
	
	userStore: UserStore;
	// @observable
	entitlements: string[] = [];
	
	constructor(useStore: UserStore) {
		// makeObservable(this);
		makeObservable(this, {
			authenticationState: observable,
			entitlements: observable,
			
			LoginGoogle: action,
			HeartBeat: action,
			AuthCheck: action,
			SetAuthenticationState: action,
			Logout: action,
			
			GetUser: computed,
			GetAuthenticationState: computed,
			GetEntitlements: computed
		});
		
		this.userStore = useStore
	}
	
	// @action
	SetAuthenticationState = (state: AuthenticationState) => {
		this.authenticationState = state;
	}
	
	/**
	 * Sends the completed OAuth2 details to the backend server to either login/sign-up
	 * @param token
	 * @constructor
	 */
	// @action
	LoginGoogle = async (token: IGoogleOAuthTokenResponse): Promise<Result<AuthObj, UIErrorV2>> => {
		let profile = token.profileObj.intoDTO()
		if (isError(profile)) {
			const error = NewUIErrorV2(
				ActionType.Authenticate,
				EntityKind.Auth,
				profile as IUIError,
				`failed to convert GoogleProfile to DTO - Err(Value = ${JSON.stringify(profile)})`
			);
			
			return LogErrorToProvider(error)
		}
		
		let tokenReq = token.tokenObj.intoDTO()
		if (isError(tokenReq)) {
			const error = NewUIErrorV2(
				ActionType.Authenticate,
				EntityKind.Auth,
				tokenReq as IUIError,
				`failed to convert GoogleOAuthToken to DTO - Err(Value = ${JSON.stringify(tokenReq)})`
			)
			
			return LogErrorToProvider(error);
		}
		
		let loginReq = new LoginRequest();
		loginReq.setProfile(profile as SignUpProfileDTO);
		loginReq.setToken(tokenReq as GoogleOAuthTokenDTO);
		
		try {
			const response: LoginResponse =
				await authClient.login(loginReq, undefined);
			
			console.log("here: 1")
			
			if (!response.getJwt()) {
				return LogErrorToProvider(NewUIErrorV2(
					ActionType.Authenticate,
					EntityKind.Auth,
					InternalErrorTypes.AuthenticationGoogle,
					`authentication JWT response from backend is nil`
				));
			}
			
			console.log("here: 2")
			
			if (!response.getUserId()) {
				return LogErrorToProvider(NewUIErrorV2(
					ActionType.Authenticate,
					EntityKind.Auth,
					InternalErrorTypes.AuthenticationGoogle,
					`authentication userId response from backend is nil`
				));
			}
			
			console.log("here: 3")
			
			if (!response.getUser()) {
				throw NewUIErrorV2(ActionType.Authenticate, EntityKind.User, "user is empty")
			}
			
			console.log("here: 4")
			
			if (!response.getEntitlementsList()) {
				throw NewUIErrorV2(ActionType.Authenticate, EntityKind.User, "entitlements is empty")
			}
			
			console.log("here: 5")
			
			const token: JWTToken = new JWTToken();
			token.fromDTO(response.getJwt()!)
			
			if (isError(token)) {
				throw NewUIErrorV2(ActionType.Authenticate, EntityKind.JWTToken, token as unknown as IUIError)
			}
			
			console.log("here: 6")
			
			let user = new User();
			user.fromDTO(response.getUser()!);
			
			console.log("here: 7")
			
			if (isError(user)) {
				throw NewUIErrorV2(ActionType.Authenticate, EntityKind.User, user as unknown as IUIError)
			}
			
			console.log("here: 8")
			
			runInAction(() => {
				this.SaveAuth({
					jwt: token,
					userId: response.getUserId()!.getValue(),
					user: user
				});
			})
			
			console.log("here: 9")
			
			const entitlements1 = response.getEntitlementsList().map((e) => e.toLowerCase());
			runInAction(() => {
				this.entitlements = entitlements1
				localStorage.setItem(LOCAL_STORAGE_ENTITLEMENTS, JSON.stringify(entitlements1))
				localStorage.setItem(LOCAL_STORAGE_SUBSCRIPTION_PRODUCT, JSON.stringify(response.getSubscriptionProduct()))
			});
			
			return Ok({
				jwt: token,
				userId: response.getUserId()!.getValue(),
				user: user
			} as AuthObj)
		} catch (error) {
			console.log("caught error login: ", error)
			if (error instanceof GrpcError) {
				throw error as GrpcError;
				// Handle gRPC-Web error
				// if (error.metadata) {
				// 	// console.log("metadata: ", error.metadata)
				// 	let errorStatus = error.metadata.customErrorCode;
				// 	let customerId = error.metadata.customerId;
				//
				// 	console.log("customer_id: ", customerId);
				//
				// 	return Err(NewUIErrorV2(
				// 		ActionType.Authenticate,
				// 		EntityKind.Auth,
				// 		error,
				// 		`failed to send Google OAuth Information to Backend`,
				// 		``,
				// 		errorStatus,
				// 		[customerId],
				// 		{
				// 			"customer_id": customerId
				// 		}
				// 	));
				// }
			}
			
			// isErrorStatusDto()
			return LogErrorToProvider(NewUIErrorV2(
				ActionType.Authenticate,
				EntityKind.Auth,
				error,
				`failed to send Google OAuth Information to Backend`,
			));
		}
	}
	// @action
	/**
	 * Sends the completed OAuth2 details to the backend server to sign-up. The user won't have access to the application until they select the subscription plan
	 * and then perform a login.
	 * @param token
	 * @constructor
	 */
	SignUpGoogle = async (token: IGoogleOAuthTokenResponse, pricingSession: PricingSession | null): Promise<Result<SignUpResponse, UIErrorV2>> => {
		if (token.profileObj.email && ENVIRONMENT === "staging" && ALLOWED_EMAILS) {
			const allowedEmails = ALLOWED_EMAILS.split(",");
			if (allowedEmails.length > 0 && !allowedEmails.includes(token.profileObj.email)) {
				return LogErrorToProvider(NewUIErrorV2(
					ActionType.Authenticate,
					EntityKind.Auth,
					`email not in whitelist`,
					`email ${token.profileObj.email} not in whitelist`
				));
			}
		}
		
		let profile = token.profileObj.intoDTO()
		if (isError(profile)) {
			return LogErrorToProvider(NewUIErrorV2(
				ActionType.Authenticate,
				EntityKind.Auth,
				profile as IUIError,
				`failed to convert GoogleProfile to DTO - Err(Value = ${JSON.stringify(profile)})`
			));
		}
		
		let tokenReq = token.tokenObj.intoDTO()
		if (isError(tokenReq)) {
			return LogErrorToProvider(NewUIErrorV2(
				ActionType.Authenticate,
				EntityKind.Auth,
				tokenReq as IUIError,
				`failed to convert GoogleOAuthToken to DTO - Err(Value = ${JSON.stringify(tokenReq)})`
			));
		}
		
		let signUpReq = new SignUpRequest();
		signUpReq.setProfile(profile as SignUpProfileDTO);
		signUpReq.setToken(tokenReq as GoogleOAuthTokenDTO);
		if (pricingSession) {
			let dto = new PricingSessionDto();
			dto.setPriceId(pricingSession.priceId);
			dto.setSessionId(pricingSession.sessionId);
			signUpReq.setPricingSession(dto)
		}
		
		// TODO: We need to send the subscription plan here
		
		try {
			const response: SignUpResponse =
				await authClient.signUp(signUpReq);
			
			if (!response.getSession() && !response.getCustomerId()) {
				return LogErrorToProvider(NewUIErrorV2(
					ActionType.Authenticate,
					EntityKind.Auth,
					`authentication session response from backend AND customer_id is nil - this should not happen`,
				));
			}
			
			runInAction(() => {
				this.SaveAuthState({
					state: AuthenticationStateEnum.SignedUpPendingSubscription,
					payload: null,
				});
			})
			
			return Ok(response)
		} catch (error: any) {
			if (error instanceof GrpcError) {
				// Handle based on grpcStatus and grpcMessage
				console.error(`Handled gRPC Error - Status: ${error.grpcStatus}, Message: ${error.grpcMessage}`);
			} else {
				console.error("Unexpected error:", error);
			}
			
			console.log("caught error signup: ", error)
			if (error instanceof grpcWeb.RpcError) {
				// Handle gRPC-Web error
				if (error.metadata[GRPC_CARTA_ERROR_HEADER]) {
					let errorStatus = error.metadata[GRPC_CARTA_ERROR_HEADER] as unknown as ErrorStatusDTO
					let customerId = error.metadata["customer_id"]
					
					return Err(NewUIErrorV2(
						ActionType.Authenticate,
						EntityKind.Auth,
						error,
						`failed to send Google OAuth Information to Backend`,
						``,
						errorStatus,
						[customerId]
					));
				}
				// const errorCode = error.metadata.metadataMap[GRPC_CARTA_ERROR_HEADER]
			} else {
				// Handle other types of errors
			}
			
			// Handle the error
			return LogErrorToProvider(NewUIErrorV2(
				ActionType.Authenticate,
				EntityKind.Auth,
				error,
				`failed to google signup`,
			));
		}
	}
	
	AuthCheck = async (): Promise<boolean> => {
		try {
			const lastHeartbeat = getLastHeartBeatTimeFromLocalStorage();
			if (lastHeartbeat != null) {
				const now = new Date();
				const diff = now.getTime() - lastHeartbeat.getTime();
				if (diff < 1000 * 60 * 60) {
					return true;
				}
			}
			
			const resp = await this.HeartBeat();
			if (resp.ok) {
				const heartbeat = resp.value
				
				if (heartbeat) {
					// We can confirm the user object is still in storage, if not a call to getMe should work
					
					let user = retrieveUserFromLocalStorage();
					if (user == null) {
						console.warn("User object not found despite successful heartbeat ... fetching getMe user.")
						
						const fetchedUser = await this.userStore.GetMe();
						if (fetchedUser.ok) {
							user = fetchedUser.value;
						} else {
							throw new Error("Failed to fetch user from backend");
						}
					}
					
					runInAction(() => {
						this.SetAuthenticationState({
							state: AuthenticationStateEnum.LoggedIn,
							payload: {
								user: user,
							}
						});
					})
					
					storeHeartBeatTimeInLocalStorage(new Date());
					
					return true;
				} else {
					return false
				}
			} else {
				// Logout
				console.error("Heartbeat failed, logging out user: ", resp.error)
				this.InvalidateAuthState();
				
				await this.Logout();
				
				return false
			}
		} catch (err) {
			console.error(err);
			
			this.InvalidateAuthState();
			
			await this.Logout();
			
			return false
		}
	};
	// @action
	HeartBeat = async (): Promise<Result<boolean, IUIError>> => {
		let req = new HeartbeatRequest();
		
		try {
			const response = await authClient.heartbeat(req)
			
			// console.log("Heartbeat response: ", response.getIsAlive())
			return Ok(response.getIsAlive())
		} catch (e) {
			return LogErrorToProvider(NewUIErrorV2(
				ActionType.HeartBeat,
				EntityKind.Auth,
				`failed to send heartbeat to backend - Err(Value = ${JSON.stringify(e)})`
			));
		}
	}
	// @action
	Logout = async () => {
		let req = new LogoutRequest();
		try {
			
			// clearStores()
			await authClient.logout(req)
			this.InvalidateAuthState();
			removeAuthInfoFromLocalStorage()
		} catch (e) {
			return LogErrorToProvider(NewUIErrorV2(
				ActionType.Logout,
				EntityKind.Auth,
				`failed to logout to backend - Err(Value = ${JSON.stringify(e)})`
			));
		}
	}
	
	SaveAuth = (auth: AuthObj) => {
		this.SetAuthenticationState({
			state: AuthenticationStateEnum.LoggedIn,
			payload: {
				user: auth.user,
			}
		});
		
		storeAuthObjInLocalStorage(auth);
	}
	
	SaveAuthState = (auth: AuthenticationState) => {
		this.SetAuthenticationState(auth);
	}
	
	InvalidateAuthState = () => {
		this.SetAuthenticationState({
			state: AuthenticationStateEnum.LoggedOff,
			payload: null
		});
		
		removeAuthInfoFromLocalStorage();
	}
	
	// @computed
	get GetUser(): User | null {
		if (this.authenticationState.state === AuthenticationStateEnum.LoggedIn) {
			return retrieveUserFromLocalStorage()
		}
		
		// console.log("authenticationState is not logged in", this.authenticationState)
		
		return null
	}
	// @computed
	get GetAuthenticationState(): AuthenticationState {
		return this.authenticationState
	}
	// @computed
	get GetEntitlements(): string[] {
		const res = localStorage.getItem(LOCAL_STORAGE_ENTITLEMENTS)
		if (res) {
			return JSON.parse(res)
		}
		
		return []
	}
}

export enum AuthenticationStateEnum {
	LoggedIn,
	LoggedOff,
	SignedUpPendingSubscription,
}

export interface AuthenticationState {
	state: AuthenticationStateEnum,
	payload: AuthPayload | null
}

interface AuthPayload {
	user: User,
}

