// material-ui
import { Theme } from '@mui/material/styles';

// project import
import getColors from 'utils/getColors';

// types
import { ExtendedStyleProps } from 'types/extended';

// ==============================|| CHIP - COLORS ||============================== //

function getColor({ color, theme }: ExtendedStyleProps) {
  const colors = getColors(theme, color);
  const { dark } = colors;

  return {
    '&.Mui-focusVisible': {
      outline: `2px solid ${dark}`,
      outlineOffset: 2
    }
  };
}

function getColorStyle({ color, theme }: ExtendedStyleProps) {
  const colors = getColors(theme, color);
  const { light, lighter, main } = colors;

  return {
    color: main,
    backgroundColor: lighter,
    borderColor: light,
    '& .MuiChip-deleteIcon': {
      color: main,
      '&:hover': {
        color: light
      }
    }
  };
}

import { alpha } from '@mui/material/styles';

// Utility to calculate luminance and determine text color
export function getContrastText(backgroundColor: string, theme: Theme): string {
  console.log('backgroundColor', backgroundColor);
  // Wrap the backgroundColor into a valid color object
  const augmentedColor = theme.palette.augmentColor({
    color: { main: backgroundColor } // Proper format for augmentColor
  });
  
  // Extract the main color
  const mainColor = augmentedColor.main;
  
  // Convert main color to RGB for luminance calculation
  const { r, g, b } = hexToRGB(mainColor);
  
  // Calculate relative luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  
  // Return white for dark backgrounds, black for light backgrounds
  return luminance > 0.5 ? theme.palette.common.black : theme.palette.common.white;
}

// Helper function to convert HEX to RGB
function hexToRGB(hex: string): { r: number; g: number; b: number } {
  const match = hex.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i);
  if (!match) throw new Error('Invalid HEX color format');
  return {
    r: parseInt(match[1], 16),
    g: parseInt(match[2], 16),
    b: parseInt(match[3], 16)
  };
}



// ==============================|| OVERRIDES - CHIP ||============================== //

export default function Chip(theme: Theme) {
  const defaultLightChip = getColorStyle({ color: 'secondary', theme });
  return {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          backgroundColor: theme.palette.primary.main, // Example background color
          color: getContrastText(theme.palette.primary.main, theme) // Dynamic text color
        },
        sizeLarge: {
          fontSize: '1rem',
          height: 40
        },
        outlined: {
          borderColor: theme.palette.primary.light,
        },
        clickable: {
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          }
        }
      }
    }
  };
}