import {action, computed, makeObservable, observable, ObservableMap, runInAction,} from "mobx";
import {CardStore} from "stores/CardStore";
import {TopicStore} from "stores/TopicStore";
import {ResourceStore} from "stores/ResourceStore";
import {TagStore} from "stores/TagStore";
import {InternalErrorTypes, IUIError, LogU, NewUIError,} from "service/cartaError";
import {ListOptionsRequestDTO, TimestampDTO} from "proto/utils_pb";
import {Card} from "model/Card";
import {Err, Ok, Result} from "utils/result";

export class CardLandingPageStore {
	cardStore: CardStore;
	topicStore: TopicStore;
	resourceStore: ResourceStore;
	tagStore: TagStore;
	
	// @observable
	selectedCard?: Card;
	// @observable
	public nextPageCursor: TimestampDTO = new TimestampDTO();
	// @observable
	public previousPageCursor: TimestampDTO = new TimestampDTO();
	// @observable
	public cards: ObservableMap<string, Card> = new ObservableMap<string, Card>();
	
	constructor(
		cardStore: CardStore,
		topicStore: TopicStore,
		resourceStore: ResourceStore,
		tagStore: TagStore
	) {
		makeObservable(this, {
			cards: observable,
			nextPageCursor: observable,
			previousPageCursor: observable,
			selectedCard: observable,
			
			SetSelectedCard: action,
			GetCard: action,
			FetchCards: action,
			
			SelectedCard: computed,
			Card: computed,
			// GetCursorLastSeen: computed,
		});
		
		this.cardStore = cardStore;
		this.topicStore = topicStore;
		this.tagStore = tagStore;
		this.resourceStore = resourceStore;
	}
	
	Clear() {
		this.selectedCard = undefined
		this.cards.clear()
	}
	
	// @action
	get SelectedCard(): Card | undefined {
		if (this.selectedCard) {
			return this.cards.get(this.selectedCard.id);
		} else {
			return undefined;
		}
	}
	
	get Card(): Card[] {
		return Array.from(this.cardStore.map.values());
	}
	
	public GetNextCard = (): Card | undefined => {
		if (this.selectedCard) {
			const index = this.Card.findIndex((card) => card.id === this.selectedCard!.id);
			if (index < this.Card.length - 1) {
				return this.Card[index + 1];
			}
		}
		
		return undefined;
	}
	
	public GetPreviousCard = (): Card | undefined => {
		if (this.selectedCard) {
			const index = this.Card.findIndex((card) => card.id === this.selectedCard!.id);
			if (index > 0) {
				return this.Card[index - 1];
			}
		}
		
		return undefined;
	}
	
	public SetSelectedCard = (id: string) => {
		const selected = this.cards.get(id);
		
		if (selected) {
			this.selectedCard = selected;
		} else {
			this.cardStore
				.GetOneOrFetch(id)
				.then((res) => {
					if (res.ok) {
						this.selectedCard = res.value as Card;
					} else {
						LogU(
							NewUIError(
								"CardLandingPageStore",
								InternalErrorTypes.LocalNotFound,
								`Card with id ${id} not found`
							)
						);
					}
				})
				.catch((err) => {
					LogU(
						NewUIError(
							"CardLandingPageStore",
							InternalErrorTypes.LocalNotFound,
							`Card with id ${id} not found`
						)
					);
				});
		}
	};
	
	public delete = async (id: string): Promise<Result<void, IUIError>> => {
		const res = await this.cardStore.Delete(id)
		if (res.ok) {
			if (this.cardStore.GetList.length > 0) {
				const nextCard = this.GetNextCard();
				if (nextCard) {
					this.SetSelectedCard(nextCard.id);
				} else {
					if (this.Card.length > 0) {
						this.SetSelectedCard(this.Card[0].id);
					}
				}
			}
		}
		
		return res
	};
	
	// @action
	public GetCard = (id: string): Promise<Card | IUIError> => {
		if (this.cards.has(id)) {
			return Promise.resolve(this.cards.get(id)!);
		}
		
		return this.cardStore
			.Get(id)
			.then((res) => {
				if (res.ok) {
					return res.value as Card;
				} else {
					return res.error as IUIError;
				}
			})
			.catch((err) => {
				return err as IUIError;
			});
	};
	
	// @action
	public FetchCards = async (
		invalidate?: boolean,
		opts?: ListOptionsRequestDTO
	): Promise<Result<Card[], IUIError>> => {
		if (invalidate) {
			let newOpts = new ListOptionsRequestDTO();
			if (opts) {
				newOpts = opts;
			}
			
			return this.cardStore.ListTest(newOpts).then((res) => {
				if (res.ok) {
					runInAction(() => {
						if (res.value.info.getNext() !== undefined) {
							this.nextPageCursor = res.value.info.getNext()!;
						}
						if (res.value.info.getPrev() !== undefined) {
							this.previousPageCursor = res.value.info.getPrev()!;
						}
						
						res.value.items.forEach((card) => {
							this.cards.set(card.id, card);
						});
					});
					
					return Ok(res.value.items)
				} else {
					return Err(res.error)
				}
			});
		}
		
		return Promise.resolve(Ok(this.Card));
	};
}
