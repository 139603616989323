import {BaseModel, EntityKind} from "../BaseModel";
import {IndustryIdentifierDTO, ResourceMetadataDTO} from "proto/resource_pb";
import {IDisplayItem} from "../interfaces";
import {ActionType, isError, IUIError, NewUIErrorV2} from "service/cartaError";
import {
    convertDateToTimestamp,
    convertFromDTOToDate,
    convertFromDTOToID,
    convertTimestampToDate,
    ListItem,
    NewUUID
} from "utils/utils";
import {IFromDTO, IIntoDTO, IModel} from "../model";
import {UUID_DTO} from "proto/utils_pb";

export interface IResourceMetadata extends IModel {
    _resourceId: string;

    _language?: string;
    _doi?: string;
    _edition?: string;
    _version?: string;
    _format?: string;
    _smallThumbnail?: string;
    _categories?: string[];
    _publisher?: string;
    _publishedOn?: Date;
    _conference?: string;
    _volume?: string;
    _textSnippet?: string;
    _industryIdentifiers?: IndustryIdentifier[];
    _pageNumbers?: string;
    _pageCount?: number;
    _accessedOn?: Date;
    _subtitle?: string;
    _description?: string;
    _isDefault: boolean;
}

export class ResourceMetadata extends BaseModel<ResourceMetadata, ResourceMetadataDTO> {
    language?: string;
    resourceId: string;
    doi?: string;
    edition?: string;
    version?: string;
    format?: string;
    smallThumbnail?: string;
    categories?: string[];
    publisher?: string;
    publishedOn?: Date;
    conference?: string;
    subtitle?: string;
    description?: string;
    volume?: string;
    textSnippet?: string;
    industryIdentifiers?: IndustryIdentifier[];
    pageNumbers?: string;
    pageCount?: number;
    accessedOn?: Date;
    // We get this from the server, we never set it
    isDefault: boolean = false;

    constructor() {
        super()
        this.industryIdentifiers = [];
        this.categories = [];
        this.resourceId = "";
    }

    toListItem(): ListItem {
        return {
            id: this.doi ? this.doi : "",
            title: this.textSnippet ? this.textSnippet : "",
            metadata1: this.language ? this.language : "",
        }
    }

    toDisplayable(): IDisplayItem {
        throw new Error("Method not implemented.");
    }

    to1LineString(): String {
        throw new Error("Method not implemented.");
    }

    static fromParts(userId: string, resourceId: string): ResourceMetadata {
        let resourceMetadata = new ResourceMetadata();
        resourceMetadata.id = NewUUID();
        resourceMetadata.resourceId = resourceId;
        resourceMetadata.userId = userId;
        return resourceMetadata;
    }

    toJSON(): IResourceMetadata {
        return {
            id: this.id,
            userId: this.userId,
            createdOn: this.createdOn,
            updatedOn: this.updatedOn,
            _resourceId: this.resourceId,
            _language: this.language,
            _doi: this.doi,
            _edition: this.edition,
            _version: this.version,
            _format: this.format,
            _smallThumbnail: this.smallThumbnail,
            _categories: this.categories,
            _publisher: this.publisher,
            _publishedOn: this.publishedOn,
            _conference: this.conference,
            _volume: this.volume,
            _textSnippet: this.textSnippet,
            _industryIdentifiers: this.industryIdentifiers,
            _pageNumbers: this.pageNumbers,
            _pageCount: this.pageCount,
            _accessedOn: this.accessedOn,
            _subtitle: this.subtitle,
            _description: this.description,
            _isDefault: this.isDefault
        };
    }

    static fromJSON(temp: IResourceMetadata): ResourceMetadata {
        let resourceMetadata = new ResourceMetadata();

        resourceMetadata.id = temp.id;
        resourceMetadata.userId = temp.userId;
        
        if (temp.createdOn) {
            resourceMetadata.createdOn = new Date(temp.createdOn);
        } else {
            throw NewUIErrorV2(ActionType.ConvertFromJSON, EntityKind.ResourceMetadata, "createdOn is required");
        }
        if (temp.updatedOn) {
            resourceMetadata.updatedOn = new Date(temp.updatedOn);
        } else {
            throw NewUIErrorV2(ActionType.ConvertFromJSON, EntityKind.ResourceMetadata, "updatedOn is required");
        }

        resourceMetadata.accessedOn = temp._accessedOn ? new Date(temp._accessedOn) : undefined;
        resourceMetadata.categories = temp._categories;
        resourceMetadata.conference = temp._conference;
        resourceMetadata.doi = temp._doi;
        resourceMetadata.edition = temp._edition;
        resourceMetadata.format = temp._format;
        resourceMetadata.industryIdentifiers = temp._industryIdentifiers;
        resourceMetadata.language = temp._language;
        resourceMetadata.pageCount = temp._pageCount;
        resourceMetadata.pageNumbers = temp._pageNumbers;
        resourceMetadata.publishedOn = temp._publishedOn ? new Date(temp._publishedOn) : undefined;
        resourceMetadata.publisher = temp._publisher;
        resourceMetadata.smallThumbnail = temp._smallThumbnail;
        resourceMetadata.textSnippet = temp._textSnippet;
        resourceMetadata.version = temp._version;
        resourceMetadata.volume = temp._volume;
        resourceMetadata.description = temp._description;
        resourceMetadata.subtitle = temp._subtitle;
        resourceMetadata.resourceId = temp._resourceId;

        return resourceMetadata;
    }
    
    static toJSON(temp: ResourceMetadata): IResourceMetadata {
        return {
            _accessedOn: temp.accessedOn,
            _categories: temp.categories,
            _conference: temp.conference,
            _description: temp.description,
            _doi: temp.doi,
            _edition: temp.edition,
            _format: temp.format,
            _industryIdentifiers: temp.industryIdentifiers,
            _isDefault: temp.isDefault,
            _language: temp.language,
            _pageCount: temp.pageCount,
            _pageNumbers: temp.pageNumbers,
            _publishedOn: temp.publishedOn,
            _publisher: temp.publisher,
            _smallThumbnail: temp.smallThumbnail,
            _subtitle: temp.subtitle,
            _textSnippet: temp.textSnippet,
            _version: temp.version,
            _volume: temp.volume,
            _resourceId: temp.resourceId,
            id: temp.id,
            userId: temp.userId,
            createdOn: temp.createdOn,
            updatedOn: temp.updatedOn
        }
    }

    TYPE: EntityKind = EntityKind.ResourceMetadata

    fromDTO(dto: ResourceMetadataDTO): void | IUIError {
        this.id = convertFromDTOToID('id', this.TYPE, dto.getId());
        this.userId = convertFromDTOToID('userId', this.TYPE, dto.getUserId());
        this.publishedOn = convertFromDTOToDate('publishedOn', this.TYPE, dto.getPublishedOn(), true);
        this.accessedOn = convertFromDTOToDate('publishedOn', this.TYPE, dto.getAccessedOn(), true);
        this.resourceId = convertFromDTOToID('resourceId', this.TYPE, dto.getResourceId());
        this.language = dto.getLanguage();
        this.doi = dto.getDoi();
        this.edition = dto.getEdition();
        this.version = dto.getVersion();
        this.format = dto.getFormat();
        this.smallThumbnail = dto.getSmallThumbnail();
        this.categories = dto.getCategoriesList();
        this.publisher = dto.getPublisher();
        this.conference = dto.getConference();
        this.volume = dto.getVolume();
        this.subtitle = dto.getSubtitle();
        this.description = dto.getDescription();
        this.textSnippet = dto.getTextSnippet();
        this.pageNumbers = dto.getPageNumbers();
        this.pageCount = dto.getPageCount();
        this.isDefault = dto.getIsdefault(); // We get this from the server,

        let identifiers: IndustryIdentifier[] = []
        if (dto.getIndustryIdentifiersList() !== undefined) {
            dto.getIndustryIdentifiersList().forEach((x) => {

                let type = x.getTypes() as IndustryIdentifierTypes;

                let y = new IndustryIdentifier(type, x.getIdentifier())
                identifiers.push(y)
            })
        }

        this.industryIdentifiers = identifiers;
    }

    intoDTO(): IUIError | ResourceMetadataDTO {
        let published_on;
        if (this.publishedOn !== undefined) {
            published_on = convertDateToTimestamp(this.publishedOn);
        }

        let accessed_on;
        if (this.accessedOn !== undefined) {
            accessed_on = convertDateToTimestamp(this.accessedOn);
        }

        let identifiers: IndustryIdentifierDTO[] = []
        if (this.industryIdentifiers !== undefined) {
            this.industryIdentifiers.forEach((x) => {
                let y = x.intoDTO()
                if (isError(y)) {
                    return y
                }

                identifiers.push(y as IndustryIdentifierDTO)
            })
        }

        let dto = new ResourceMetadataDTO();
        dto.setLanguage(this.language ? this.language : '');
        dto.setDoi(this.doi ? this.doi : '');
        dto.setEdition(this.edition ? this.edition : '');
        dto.setVersion(this.version ? this.version : '');
        dto.setFormat(this.format ? this.format : '');
        dto.setSmallThumbnail(this.smallThumbnail ? this.smallThumbnail : '');
        dto.setCategoriesList(this.categories ? this.categories : []);
        dto.setPublisher(this.publisher ? this.publisher : '');
        dto.setPublishedOn(published_on);
        dto.setConference(this.conference ? this.conference : '');
        dto.setVolume(this.volume ? this.volume : '');
        dto.setTextSnippet(this.textSnippet ? this.textSnippet : '');
        dto.setIndustryIdentifiersList(identifiers);
        dto.setPageNumbers(this.pageNumbers ? this.pageNumbers : '');
        dto.setPageCount(this.pageCount ? this.pageCount : 0);
        dto.setAccessedOn(accessed_on);
        dto.setSubtitle(this.subtitle ? this.subtitle : '');
        dto.setDescription(this.description ? this.description : '');
        dto.setUserId(new UUID_DTO().setValue(this.userId));
        dto.setId(new UUID_DTO().setValue(this.id));
        dto.setResourceId(new UUID_DTO().setValue(this.resourceId));
        dto.setCreatedon(convertDateToTimestamp(this.createdOn));
        dto.setIsdefault(this.isDefault);
        return dto;
    }

    clone(): ResourceMetadata {
        let resourceMetadata = new ResourceMetadata();

        resourceMetadata.id = this.id;
        resourceMetadata.userId = this.userId;
        resourceMetadata.createdOn = this.createdOn;
        resourceMetadata.updatedOn = this.updatedOn;
        resourceMetadata.resourceId = this.resourceId;
        resourceMetadata.accessedOn = this.accessedOn;
        resourceMetadata.categories = this.categories;
        resourceMetadata.conference = this.conference;
        resourceMetadata.doi = this.doi;
        resourceMetadata.edition = this.edition;
        resourceMetadata.format = this.format;
        resourceMetadata.industryIdentifiers = this.industryIdentifiers ? [...this.industryIdentifiers] : undefined;
        resourceMetadata.language = this.language;
        resourceMetadata.pageCount = this.pageCount;
        resourceMetadata.pageNumbers = this.pageNumbers;
        resourceMetadata.publishedOn = this.publishedOn;
        resourceMetadata.publisher = this.publisher;
        resourceMetadata.smallThumbnail = this.smallThumbnail;
        resourceMetadata.textSnippet = this.textSnippet;
        resourceMetadata.version = this.version;
        resourceMetadata.volume = this.volume;
        resourceMetadata.description = this.description;
        resourceMetadata.subtitle = this.subtitle;

        return resourceMetadata;
    }

    sanitize(): ResourceMetadata {
        this.language = this.language?.trim();
        this.doi = this.doi?.trim();
        this.edition = this.edition?.trim();
        this.version = this.version?.trim();
        this.format = this.format?.trim();
        this.smallThumbnail = this.smallThumbnail?.trim();
        this.categories = this.categories?.map((x) => x.trim());
        this.publisher = this.publisher?.trim();
        this.conference = this.conference?.trim();
        this.volume = this.volume?.trim();
        this.textSnippet = this.textSnippet?.trim();
        this.pageNumbers = this.pageNumbers?.trim();
        this.subtitle = this.subtitle?.trim();
        this.description = this.description?.trim();

        return this;
    }

    customValidate(): IUIError | ResourceMetadata {
        // I want the validation to ensure the fields if not empty are between 2 and 250 characters
        // and dont contain characters that may be harmful to the system. Use the NewUIErrorV2 function to return errors
        // and populate the message param with useful information.
        
        if (this.doi !== undefined) {
            if (this.doi.length < 2 || this.doi.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "DOI is not between 2 and 250 characters"
                )
            }
        }
        if (this.language !== undefined) {
            if (this.language.length < 2 || this.language.length > 25) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Language is not between 2 and 25 characters"
                )
            }
        }
        if (this.edition !== undefined) {
            if (this.edition.length < 2 || this.edition.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Edition is not between 2 and 250 characters"
                )
            }
        }
        if (this.version !== undefined) {
            if (this.version.length < 2 || this.version.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Version is not between 2 and 250 characters"
                )
            }
        }
        if (this.format !== undefined) {
            if (this.format.length < 2 || this.format.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Format is not between 2 and 250 characters"
                )
            }
        }
        if (this.smallThumbnail !== undefined) {
            if (this.smallThumbnail.length < 2 || this.smallThumbnail.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Small Thumbnail is not between 2 and 250 characters"
                )
            }
        }
        if (this.publisher !== undefined) {
            if (this.publisher.length < 2 || this.publisher.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Publisher is not between 2 and 250 characters"
                )
            }
        }
        if (this.conference !== undefined) {
            if (this.conference.length < 2 || this.conference.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Conference is not between 2 and 250 characters"
                )
            }
        }
        if (this.volume !== undefined) {
            if (this.volume.length < 2 || this.volume.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Volume is not between 2 and 250 characters"
                )
            }
        }
        if (this.textSnippet !== undefined) {
            if (this.textSnippet.length < 2 || this.textSnippet.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Text Snippet is not between 2 and 250 characters"
                )
            }
        }
        if (this.pageNumbers !== undefined) {
            if (this.pageNumbers.length < 2 || this.pageNumbers.length > 250) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Page Numbers is not between 2 and 250 characters"
                )
            }
        }
        if (this.pageCount !== undefined) {
            if (this.pageCount < 0 || this.pageCount > 10000) {
                return NewUIErrorV2(
                    ActionType.Validate,
                    EntityKind.ResourceMetadata,
                    "Page Count is not between 0 and 10000"
                )
            }
        }
        
        return this.sanitize()
    }
}

export type IndustryIdentifierTypes = "isbn10" | "isbn13" | "issn" | "other";

export class IndustryIdentifier implements IIntoDTO<IndustryIdentifierDTO>, IFromDTO<IndustryIdentifierDTO> {
    type: IndustryIdentifierTypes;
    identifier: string;

    constructor(type: IndustryIdentifierTypes, identifier: string) {
        this.type = type;
        this.identifier = identifier;
    }

    fromDTO(t: IndustryIdentifierDTO): void | IUIError {
        this.type = t.getTypes() as IndustryIdentifierTypes;
        this.identifier = t.getIdentifier();
    }

    intoDTO(): IndustryIdentifierDTO | IUIError {
        let dto = new IndustryIdentifierDTO();
        dto.setTypes(this.type);
        dto.setIdentifier(this.identifier);
        return dto;
    }

    clone(): IndustryIdentifier {
        return new IndustryIdentifier(this.type, this.identifier);
    }
}