import {CardCompositeDTO, CardDTO, CardOriginDTO, CardStatusDTO} from "../proto/card_pb";
import {ActionType, isError, IUIError, NewUIErrorV2,} from "../service/cartaError";
import {
	convertDateToTimestamp,
	convertFromDTOToDate,
	convertFromDTOToID,
	convertTimestampToDate,
	ListItem
} from "../utils/utils";
import {UUID_DTO} from "../proto/utils_pb";
import {v4 as uuidv4} from "uuid";
import {IFromDTO, IModel} from "./model";
import {CardStatDTO} from "../proto/stats_pb";
import {ReviewCardStat} from "./ReviewCard";
import {IDisplayItem} from "./interfaces";
import {BaseModel, EntityKind, IOwnedModel} from "./BaseModel";
import {CardComposite, ICardCompositeJSON, IGenericCard} from "./CardComposite";
import {GridValidRowModel} from "@mui/x-data-grid";

import {MAX_CARD_LENGTH} from "../consts";

export interface ICard extends IGenericCard, IModel {
	_status?: CardStatusDTO;
	_composite?: ICardCompositeJSON;
	_origin?: CardOriginDTO;
	_note?: string;
	_archivedOn?: Date;
}

export interface ICardJSON extends IOwnedModel<any> {
	_front: string;
	_back: string;
	_status?: CardStatusDTO;
	_composite?: ICardCompositeJSON;
	_origin?: CardOriginDTO;
	_note?: string;
	_archivedOn?: Date;
	_createdOn: Date;
	_updatedOn: Date;
}

export class Card extends BaseModel<Card, CardDTO> implements IGenericCard, GridValidRowModel {
	status?: CardStatusDTO;
	origin?: CardOriginDTO;
	front: string;
	back: string;
	note?: string;
	archivedOn?: Date;
	composite?: CardComposite;
	
	constructor() {
		super();
		const now = new Date();
		this.back = "";
		this.front = "";
		this.id = uuidv4();
		this.userId = "";
	}
	
	toListItem(): ListItem {
		return {
			id: this.id,
			title: this.front,
			metadata1: this.back,
		}
	}
	
	init(): Card {
		return new Card();
	}
	
	TYPE: EntityKind = EntityKind.Card;
	
	/**
	 * Clears a card ensuring it's left with the id, userId, createdOn, updatedOn, and archivedOn values.
	 * It will reset status values
	 */
	public clear() {
		this.front = "";
		this.back = "";
		this.note = "";
		this.origin = undefined;
		this.status = undefined;
		this.composite = undefined;
	}
	
	static fromJSON(t: ICardJSON): Card {
		let card = new Card();
		
		card.id = t.id;
		card.userId = t.userId;
		card.origin = t._origin;
		card.status = t._status;
		card.front = t._front;
		card.back = t._back;
		card.note = t._note;
		if (t._composite) {
			card.composite = CardComposite.fromJSON(t._composite);
		}
		
		if (t._createdOn) {
			card.createdOn = new Date(t._createdOn);
		} else {
			throw NewUIErrorV2(ActionType.ConvertFromJSON, EntityKind.Card, "createdOn is required");
		}
		if (t._updatedOn) {
			card.updatedOn = new Date(t._updatedOn);
		} else {
			throw NewUIErrorV2(ActionType.ConvertFromJSON, EntityKind.Card, "updatedOn is required");
		}
		if (t._archivedOn) {
			card.archivedOn = new Date(t._archivedOn);
		}
		
		return card;
	}
	
	static fromJSONString(json: string): Card {
		let obj = JSON.parse(json);
		return Card.fromJSON(obj);
	}
	
	toJSON(): ICardJSON {
		return {
			id: this.id,
			userId: this.userId,
			_front: this.front,
			_back: this.back,
			_origin: this.origin,
			_status: this.status,
			_note: this.note,
			_composite: this.composite?.toJSON(),
			_archivedOn: this.archivedOn,
			_createdOn: this.createdOn,
			_updatedOn: this.updatedOn,
		} as ICardJSON
	}
	
	public fromDTO(dto: CardDTO): void | IUIError {
		this.id = convertFromDTOToID('id', this.TYPE, dto.getId());
		this.userId = convertFromDTOToID('userId', this.TYPE, dto.getUserId());
		this.createdOn = convertFromDTOToDate('createdOn', this.TYPE, dto.getCreatedon())!
		this.updatedOn = convertFromDTOToDate('updatedOn', this.TYPE, dto.getUpdatedon())!
		this.archivedOn = convertFromDTOToDate('lastLogin', this.TYPE, dto.getArchivedon(), true)
		this.origin = dto.getOrigin();
		this.status = dto.getStatus();
		this.front = dto.getFront();
		this.back = dto.getBack();
		this.note = dto.getNote();
		
		let composite: CardComposite = new CardComposite();
		if (dto.getComposite() !== undefined) {
			let x = composite.fromDTO(dto.getComposite()!);
			if (x) {
				return x as IUIError;
			}
		}
		
		this.composite = composite;
	}
	
	prepareMedia(): void {
		if (this.composite) {
			this.composite.media = this.composite.media.map((media) => {
				media.cardId = this.id
				
				return media
			});
		}
	}
	
	public intoDTO(): IUIError | CardDTO {
		let dto = new CardDTO();
		
		this.prepareMedia()
		
		let compositeDTO = this.composite?.intoDTO();
		if (compositeDTO && isError(compositeDTO)) {
			return NewUIErrorV2(ActionType.ConvertFromDTO, this.TYPE, `failed to convert card to DTO`);
		}
		
		dto.setId(new UUID_DTO().setValue(this.id));
		dto.setUserId(new UUID_DTO().setValue(this.userId));
		dto.setFront(this.front);
		dto.setBack(this.back);
		dto.setNote(this.note ? this.note : "")
		if (this.status !== undefined) {
			dto.setStatus(this.status);
		}
		if (this.origin !== undefined) {
			dto.setOrigin(this.origin);
		}
		if (this.composite) {
			dto.setComposite(compositeDTO as CardCompositeDTO);
		}
		if (this.archivedOn) {
			dto.setArchivedon(convertDateToTimestamp(this.archivedOn));
		}
		dto.setCreatedon(convertDateToTimestamp(this.createdOn));
		dto.setUpdatedon(convertDateToTimestamp(this.updatedOn));
		
		return dto;
	}
	
	public sanitize(): Card {
		this.front = this.front.trim();
		this.back = this.back.trim();
		this.note = this.note ? this.note.trim() : undefined;
		return this;
	}
	
	public customValidate(): Card | IUIError {
		if (this.front === "") {
			return NewUIErrorV2(ActionType.Validate, this.TYPE, "Card front cannot be empty");
		}
		if (this.back === "") {
			return NewUIErrorV2(ActionType.Validate, this.TYPE, "Card back cannot be empty");
		}
		if (this.front.length > MAX_CARD_LENGTH) {
			return NewUIErrorV2(ActionType.Validate, this.TYPE, `Card front cannot exceed ${MAX_CARD_LENGTH} characters`);
		}
		if (this.back.length > MAX_CARD_LENGTH) {
			return NewUIErrorV2(ActionType.Validate, this.TYPE, `Card front cannot exceed ${MAX_CARD_LENGTH} characters`);
		}
		
		if (this.composite) {
			let composite = this.composite.validate()
			
			if (isError(composite)) {
				return composite as IUIError
			}
			
			this.composite = composite as CardComposite
		}
		
		return this.sanitize();
	}
	
	clone(): Card {
		let clonedCard = new Card();
		clonedCard.id = this.id;
		clonedCard.userId = this.userId;
		if (this.status !== undefined) {
			clonedCard.status = this.status;
		} else {
			clonedCard.status = undefined;
		}
		if (this.origin !== undefined) {
			clonedCard.origin = this.origin;
		} else {
			clonedCard.origin = undefined;
		}
		clonedCard.front = this.front;
		clonedCard.back = this.back;
		clonedCard.note = this.note;
		
		clonedCard.createdOn = new Date(this.createdOn.getTime());
		clonedCard.updatedOn = new Date(this.updatedOn.getTime());
		clonedCard.archivedOn = this.archivedOn ? new Date(this.archivedOn.getTime()) : undefined;
		clonedCard.composite = this.composite ? this.composite.clone() : undefined;
		return clonedCard;
	}
	
	to1LineString(): String {
		return this.front;
	}
	
	toDisplayable(): IDisplayItem {
		return {
			id: this.id,
			title: this.front,
			metadata1: this.back,
		} as IDisplayItem;
	}
}

export class CardStat implements IFromDTO<CardStatDTO> {
	id: string;
	reviewSM2CardStats: ReviewCardStat[];
	reviewManualCardStats: ReviewCardStat[] = [];
	sm2ReviewAvgEasiness?: number;
	sm2ReviewAvgQuality?: number;
	sm2ReviewAvgInterval?: number;
	sm2ReviewAvgRepetitions?: number;
	manualReviewAvgQuality?: number;
	manualReviewAvgInterval?: number;
	manualReviewAvgRepetitions?: number;
	earliestReview?: Date;
	recentReview?: Date;
	
	constructor() {
		this.id = "";
		this.reviewSM2CardStats = [];
		this.sm2ReviewAvgEasiness = -1;
		this.sm2ReviewAvgQuality = -1;
		this.sm2ReviewAvgInterval = -1;
		this.sm2ReviewAvgRepetitions = -1;
	}
	
	fromDTO(dto: CardStatDTO): void | IUIError {
		this.id = convertFromDTOToID('id', EntityKind.CardStat, dto.getId());
		
		this.reviewSM2CardStats = dto.getStatsList().map((x) => {
			let stat = new ReviewCardStat();
			stat.fromDTO(x);
			return stat;
		});
		this.reviewManualCardStats = dto.getStatsList().map((x) => {
			let stat = new ReviewCardStat();
			stat.fromDTO(x);
			return stat;
		});
		this.sm2ReviewAvgQuality = dto.getSm2reviewavgquality();
		this.sm2ReviewAvgEasiness = dto.getSm2reviewavgeasiness();
		this.sm2ReviewAvgInterval = dto.getSm2reviewavginterval();
		this.sm2ReviewAvgRepetitions = dto.getSm2reviewavgrepetitions();
		if (dto.getEarliestreview()) {
			this.earliestReview = convertTimestampToDate(dto.getEarliestreview()!);
		}
		if (dto.getRecentreview()) {
			this.recentReview = convertTimestampToDate(dto.getRecentreview()!);
		}
	}
}

/// This represents stats that a card may have after going through a few reviews. If no review has been done for a card
/// then the stats should be null/undefined
export interface CardStats {
	lastReview: Date;
	reviewCount: number;
	confidences: number[];
}

// Create a function that creates a Card object with dummy data, that takes in a userId and randomly generates
// short strings for the string data
export const createMockCard = (userId: string): Card => {
	let card = new Card();
	card.front = "front";
	card.back = "back";
	card.userId = userId;
	return card;
};


export const MapCardStatusDTOToString = (status: CardStatusDTO): string => {
	switch (status) {
		case CardStatusDTO.CARDSTATUSDTO_UNKNOWN:
			return "Unknown";
		case CardStatusDTO.CARDSTATUSDTO_INCOMPLETE:
			return "Draft";
		case CardStatusDTO.CARDSTATUSDTO_INCORRECT:
			return "Incorrect";
		case CardStatusDTO.CARDSTATUSDTO_PENDING:
			return "Pending";
		default:
			return "Unknown";
	}
}