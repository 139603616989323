import {ActionType, InternalErrorTypes, isError, IUIError, NewUIError, NewUIErrorV2,} from "../service/cartaError";
import {Card} from "./Card";
import {
	convertDateToTimestamp,
	convertFromDTOToDate,
	convertFromDTOToID,
	convertTimestampToDate,
	ListItem
} from "../utils/utils";
import {EnumCardKindDTO, EnumReviewKindDTO, UUID_DTO} from "../proto/utils_pb";
import {v4 as uuidv4} from "uuid";
import {IFromDTO, IIntoDTO} from "./model";
import {CardDTO} from "../proto/card_pb";
import {IReviewCard} from "./Review";
import {IDisplayItem} from "./interfaces";
import {BaseModel, EntityKind} from "./BaseModel";
import {IGenericCard, IComposite} from "./CardComposite";
import {MAX_CARD_QUALITY, MIN_CARD_QUALITY} from "../consts";
import {ReviewCardStatDTO} from "../proto/stats_pb";
import {ReviewCardCompositeDTO, ReviewCardDTO} from "../proto/review_pb";

export class ReviewCard extends BaseModel<ReviewCard, ReviewCardDTO>
	implements IReviewCard {
	
	TYPE: EntityKind = EntityKind.ReviewSM2Card;
	quality?: number;
	interval?: number;
	easiniess?: number;
	repetitions: number;
	reviewKind: EnumReviewKindDTO
	startAt?: Date;
	endAt?: Date;
	nextReview?: Date;
	composite: ReviewCardComposite = new ReviewCardComposite()
	cardKind: EnumCardKindDTO = EnumCardKindDTO.CARDKIND_STANDARD
	archivedOn?: Date;
	
	toListItem(): ListItem {
		return {
			id: this.id,
			title: this.composite.card.front,
			metadata1: this.composite.card.back,
		}
	}
	
	constructor() {
		super();
		
		this.reviewId = "";
		this.cardId = "";
		// this._card = new Card();
		this.quality = undefined;
		this.interval = undefined;
		this.easiniess = undefined;
		this.repetitions = 0;
		this.startAt = undefined;
		this.endAt = undefined;
		this.nextReview = undefined;
		this.archivedOn = undefined;
		
		this.cardId = "";
	}
	
	static setup(card: IGenericCard): ReviewCard {
		let reviewCard = new ReviewCard();
		reviewCard.cardId = card.id
		
		switch (card.TYPE) {
			case EntityKind.Card:
				reviewCard.cardKind = EnumCardKindDTO.CARDKIND_STANDARD;
				break;
			case EntityKind.CardLang:
				reviewCard.cardKind = EnumCardKindDTO.CARDKIND_LANGUAGE;
				break;
			default:
				reviewCard.cardKind = EnumCardKindDTO.CARDKIND_STANDARD;
		}
		
		let composite = new ReviewCardComposite();
		composite.setup(card, reviewCard.id, 0)
		
		reviewCard.composite = composite;
		
		return reviewCard
	}
	
	public sanitize(): ReviewCard {
		if (this.cardId === "" && this.composite.card.id !== "") {
			this.cardId = this.composite.card.id;
		}
		
		return this;
	}
	
	fromDTO = (dto: ReviewCardDTO): void | IUIError => {
		this.id = convertFromDTOToID('id', this.TYPE, dto.getId());
		this.userId = convertFromDTOToID('userId', this.TYPE, dto.getUserid());
		this.cardId = convertFromDTOToID('cardId', this.TYPE, dto.getCardid());
		this.reviewId = convertFromDTOToID('reviewId', this.TYPE, dto.getReviewid());
		this.createdOn = convertFromDTOToDate('createdOn', this.TYPE, dto.getCreatedon())!
		this.updatedOn = convertFromDTOToDate('updatedOn', this.TYPE, dto.getUpdatedon())!
		this.archivedOn = convertFromDTOToDate('archivedOn', this.TYPE, dto.getArchivedon(), true)
		this.startAt = convertFromDTOToDate('startAt', this.TYPE, dto.getStartAt(), true)
		this.endAt = convertFromDTOToDate('endAt', this.TYPE, dto.getEndAt(), true)
		this.nextReview = convertFromDTOToDate('nextReview', this.TYPE, dto.getNextReview(), true)
		this.reviewKind = dto.getReviewkind()
		this.easiniess = dto.getEasiness() / 100;
		this.interval = dto.getInterval();
		this.quality = dto.getQuality();
		this.repetitions = dto.getRepetitions();
		
		let composite = new ReviewCardComposite();
		if (dto.getComposite()) {
			composite.fromDTO(dto.getComposite()!);
		} else {
			// return NewUIError(
			// 	"fromDTO",
			// 	InternalErrorTypes.InvalidReviewSM2Card,
			// 	`composite is undefined '' - reviewSM2Card: ${dto}"`
			// );
		}
		
		this.composite = composite;
	};
	
	intoDTO(): IUIError | ReviewCardDTO {
		let dto: ReviewCardDTO = new ReviewCardDTO();
		
		const validate = this.customValidate();
		if (isError(validate)) {
			return validate as IUIError;
		}
		const valid = validate as ReviewCard;
		
		dto.setId(new UUID_DTO().setValue(valid.id));
		dto.setReviewid(new UUID_DTO().setValue(valid.reviewId));
		dto.setUserid(new UUID_DTO().setValue(valid.userId));
		dto.setCardid(new UUID_DTO().setValue(valid.cardId));
		dto.setReviewkind(valid.reviewKind);
		
		if (this.composite) {
			const compositeDTO = this.composite.intoDTO();
			if (isError(compositeDTO)) {
				return compositeDTO as IUIError;
			}
			dto.setComposite(compositeDTO as ReviewCardCompositeDTO);
			// dto.setCardid(new UUID_DTO().setValue(this.composite.card.id));
		}
		
		dto.setCreatedon(convertDateToTimestamp(valid.createdOn));
		dto.setUpdatedon(convertDateToTimestamp(valid.updatedOn));
		
		if (valid.interval) {
			dto.setInterval(valid.interval);
		}
		if (valid.easiniess) {
			dto.setEasiness(valid.easiniess * 100);
		}
		dto.setRepetitions(valid.repetitions);
		
		if (valid.quality !== undefined && valid.quality > -1) {
			dto.setQuality(valid.quality);
		} else {
			throw NewUIErrorV2(ActionType.Validate, this.TYPE, "", "ReviewSM2Card quality has not been validated");
		}
		
		if (valid.endAt) {
			dto.setEndAt(convertDateToTimestamp(valid.endAt));
		}
		if (valid.startAt) {
			dto.setStartAt(convertDateToTimestamp(valid.startAt));
		}
		if (valid.archivedOn) {
			dto.setArchivedon(convertDateToTimestamp(valid.archivedOn));
		}
		if (valid.nextReview) {
			dto.setNextReview(convertDateToTimestamp(valid.nextReview));
		}
		
		return dto;
	}
	
	clone(): ReviewCard {
		let clonedCard = new ReviewCard();
		
		clonedCard.id = this.id;
		clonedCard.userId = this.userId;
		clonedCard.cardId = this.cardId;
		clonedCard.reviewId = this.reviewId;
		clonedCard.quality = this.quality;
		clonedCard.interval = this.interval;
		clonedCard.easiniess = this.easiniess;
		clonedCard.repetitions = this.repetitions;
		clonedCard.startAt = this.startAt;
		clonedCard.endAt = this.endAt;
		clonedCard.nextReview = this.nextReview;
		clonedCard.archivedOn = this.archivedOn;
		clonedCard.composite = this.composite;
		clonedCard.createdOn = this.createdOn;
		clonedCard.updatedOn = this.updatedOn;
		
		return clonedCard;
	}
	
	customValidate(): IUIError | ReviewCard {
		if (!this.id) {
			return NewUIErrorV2(ActionType.Validate, this.TYPE, "", "ReviewSM2Card is missing id");
		}
		
		if (!this.userId) {
			return NewUIErrorV2(ActionType.Validate, this.TYPE, "", "ReviewSM2Card is missing userId");
		}
		
		if (this.reviewId === "") {
			return NewUIErrorV2(ActionType.Validate, this.TYPE, "", "ReviewSM2Card reviewId is empty")
		}
		
		if (this.cardId === "") {
			return NewUIErrorV2(ActionType.Validate, this.TYPE, "", "ReviewSM2Card cardId is empty")
		}
		
		if (this.quality === undefined) {
			return NewUIErrorV2(ActionType.Validate, this.TYPE, "", "ReviewSM2Card quality is empty")
		}
		
		if (this.quality > MAX_CARD_QUALITY) {
			return NewUIErrorV2(ActionType.Validate, this.TYPE, "", "ReviewSM2Card quality is too high")
		}
		
		if (this.quality < MIN_CARD_QUALITY) {
			return NewUIErrorV2(ActionType.Validate, this.TYPE, "", "ReviewSM2Card quality is too low")
		}
		
		if (this.startAt === undefined) {
			return NewUIErrorV2(ActionType.Validate, this.TYPE, "", "ReviewSM2Card startAt is empty")
		}
		
		if (this.endAt === undefined) {
			return NewUIErrorV2(ActionType.Validate, this.TYPE, "", "ReviewSM2Card endAt is empty")
		}
		
		return this;
	}
	
	to1LineString(): String {
		return "";
	}
	
	toDisplayable(): IDisplayItem {
		return {
			id: this.id,
			title: this.composite ? this.composite.card.front : "",
		};
	}
	
	cardId: string;
	reviewId: string;
	
	init(): ReviewCard {
		return new ReviewCard()
	}
}

export class ReviewCardStat implements IFromDTO<ReviewCardStatDTO> {
	id: string = "";
	cardId: string = "";
	topicId: string = "";
	reviewId: string = "";
	easiness?: number;
	quality?: number;
	interval?: number;
	repetitions?: number;
	endAt?: Date;
	startAt?: Date;
	
	// intoDTO(): ReviewStatDTO {
	// 		let dto = new ReviewStatDTO();
	// 		dto.setId(new UUID_DTO().setValue(this.id));
	// 		dto.setReviewid(new UUID_DTO().setValue(this.reviewId));
	// 		dto.setUserid(new UUID_DTO().setValue(this.userId));
	// 		dto.setAvgcompletedquality(this.avgCompletedQuality || 0);
	// 		dto.setAvgcompletedrepetition(this.avgCompletedRepetitions || 0);
	// 		dto.setAvgcompletedinterval(this.avgCompletedInterval || 0);
	// 		dto.setAvgquality(this.avgQuality || 0);
	// 		dto.setAvgrepetition(this.avgRepetition || 0);
	// 		dto.setAvginterval(this.avgInterval || 0);
	// 		dto.setTotalCardCount(this.totalCardCount);
	// 		dto.setTotalTopicCount(this.totalTopicCount);
	//
	// 		if (this.startAt) {
	// 			dto.setStartat(convertDateToTimestamp(this.startAt));
	// 		}
	// 		if (this.endAt) {
	// 			dto.setEndat(convertDateToTimestamp(this.endAt));
	// 		}
	// 		dto.setCreatedon(convertDateToTimestamp(this.createdOn));
	//
	// 		dto.setComposite(this.composite ? this.composite.intoDTO() : undefined);
	//
	// 		this.strongestCards.forEach((x) => {
	// 			dto.addStrongestcards(new UUID_DTO().setValue(x));
	// 		});
	// 		this.weakestCards.forEach((x) => {
	// 			dto.addWeakestcards(new UUID_DTO().setValue(x));
	// 		});
	// 		this.mostReviewedCards.forEach((x) => {
	// 			dto.addMostrepeatedcards(new UUID_DTO().setValue(x));
	// 		});
	// 		this.mostReviewedTopics.forEach((x) => {
	// 			dto.addMostReviewedTopics(new UUID_DTO().setValue(x));
	// 		});
	// 		this.strongestTopics.forEach((x) => {
	// 			dto.addStrongestTopics(new UUID_DTO().setValue(x));
	// 		});
	// 		this.weakestTopics.forEach((x) => {
	// 			dto.addWeakestTopics(new UUID_DTO().setValue(x));
	// 		});
	// 		this.allTopics.forEach((x) => {
	// 			dto.addAllTopics(new UUID_DTO().setValue(x));
	// 		});
	//
	// 		this.reviewCards.forEach((x) => {
	// 			dto.addReviewcardstats(x.intoDTO());
	// 		});
	//
	// 		return dto;
	// 	}
	
	fromDTO(dto: ReviewCardStatDTO): void | IUIError {
		this.id = convertFromDTOToID('id', EntityKind.ReviewCardStats, dto.getId());
		this.reviewId = convertFromDTOToID('reviewId', EntityKind.ReviewCardStats, dto.getReviewid());
		this.cardId = convertFromDTOToID('cardId', EntityKind.ReviewCardStats, dto.getCardid());
		this.topicId = convertFromDTOToID('topicId', EntityKind.ReviewCardStats, dto.getTopicid());
		
		this.easiness = dto.getEasiness();
		this.repetitions = dto.getRepetitions();
		this.quality = dto.getQuality();
		this.interval = dto.getInterval();
		
		this.startAt = convertFromDTOToDate('startAt', EntityKind.ReviewCardStats, dto.getStartat(), true)
		this.endAt = convertFromDTOToDate('endAt', EntityKind.ReviewCardStats, dto.getEndat(), true)
	}
	
	intoDTO(): ReviewCardStatDTO {
		let dto = new ReviewCardStatDTO();
		
		dto.setId(new UUID_DTO().setValue(this.id));
		dto.setReviewid(new UUID_DTO().setValue(this.reviewId));
		dto.setCardid(new UUID_DTO().setValue(this.cardId));
		dto.setTopicid(new UUID_DTO().setValue(this.topicId));
		dto.setEasiness(this.easiness || 0);
		if (this.repetitions) {
			dto.setRepetitions(this.repetitions);
		}
		dto.setQuality(this.quality || 0);
		dto.setInterval(this.interval || 0);
		if (this.startAt) {
			dto.setStartat(convertDateToTimestamp(this.startAt));
		}
		if (this.endAt) {
			dto.setEndat(convertDateToTimestamp(this.endAt));
		}
		
		return dto;
	}
}

export interface IReviewCardComposite<C extends IGenericCard> extends IComposite {
	card: C,
	
	init(): IReviewCardComposite<C>
	
	setup(card: IGenericCard, id: string, ord: number): void
}

export class ReviewCardComposite implements IReviewCardComposite<IGenericCard>,
	IIntoDTO<ReviewCardCompositeDTO>, IFromDTO<ReviewCardCompositeDTO> {
	id: string = "";
	ord: number = 0;
	card: IGenericCard = new Card();
	
	TYPE: EntityKind = EntityKind.ReviewSM2CardComposite;
	
	constructor() {
		this.id = uuidv4();
	}
	
	public sanitize(): ReviewCardComposite {
		return this;
	}
	
	
	to1LineString(): String {
		return "";
	}
	
	toDisplayable(): IDisplayItem {
		return {
			id: this.id,
			title: this.card?.front || "",
		};
	}
	
	setup(card: IGenericCard, id: string, ord: number) {
		this.card = card;
		this.id = id;
		this.ord = ord;
	}
	
	init(): IReviewCardComposite<IGenericCard> {
		return new ReviewCardComposite()
	}
	
	fromDTO(t: ReviewCardCompositeDTO): void | IUIError {
		let card = new Card()
		if (t.getCard()) {
			card.fromDTO(t.getCard()!)
		} else {
			return NewUIError(
				"fromDTO",
				InternalErrorTypes.InvalidReviewSM2Card,
				`card is undefined '' - reviewSM2Card: ${t}"`
			);
		}
		
		this.id = convertFromDTOToID('id', EntityKind.ReviewSM2CardComposite, t.getSupertypeId())
		this.card = card
	}
	
	intoDTO(): IUIError | ReviewCardCompositeDTO {
		let dto = new ReviewCardCompositeDTO();
		
		let card_dto = new CardDTO();
		if (this.card) {
			card_dto = this.card.intoDTO() as CardDTO;
		}
		
		dto.setCard(card_dto)
		
		return dto
	}
}

// export class ReviewSM2CardStat implements IFromDTO<ReviewSM2CardStatDTO> {
//   id: string = "";
//   cardId: string = "";
//   reviewId: string = "";
//   quality?: number;
//   interval?: number;
//   repetitions?: number;
//   endAt?: Date;
//   startAt?: Date;
//
//   fromDTO(reviewCard: ReviewSM2CardStatDTO): void | IUIError {
//     if (reviewCard.getId()) {
//       if (reviewCard.getId()!.getValue()) {
//         this.id = reviewCard.getId()!.getValue();
//       } else {
//         return NewUIError(
//             "fromDTO",
//             InternalErrorTypes.InvalidReviewCard,
//             `getId is empty '' - reviewCard: ${reviewCard}"`
//         );
//       }
//     } else {
//       return NewUIError(
//           "fromDTO",
//           InternalErrorTypes.InvalidReviewCard,
//           `getId is undefined '' - reviewCard: ${reviewCard}"`
//       );
//     }
//
//     if (reviewCard.getCardid()) {
//       if (reviewCard.getCardid()!.getValue()) {
//         this.cardId = reviewCard.getCardid()!.getValue();
//       } else {
//         return NewUIError(
//             "fromDTO",
//             InternalErrorTypes.InvalidReviewCard,
//             `getCardid is empty '' - reviewCard: ${reviewCard}"`
//         );
//       }
//     } else {
//       return NewUIError(
//           "fromDTO",
//           InternalErrorTypes.InvalidReviewCard,
//           `getCardid is undefined '' - reviewCard: ${reviewCard}"`
//       );
//     }
//
//     if (reviewCard.getReviewid()) {
//       if (reviewCard.getReviewid()!.getValue()) {
//         this.reviewId = reviewCard.getReviewid()!.getValue();
//       } else {
//         return NewUIError(
//             "fromDTO",
//             InternalErrorTypes.InvalidReviewCard,
//             `getReviewid is empty '' - reviewCard: ${reviewCard}"`
//         );
//       }
//     } else {
//       return NewUIError(
//           "fromDTO",
//           InternalErrorTypes.InvalidReviewCard,
//           `getReviewid is undefined '' - reviewCard: ${reviewCard}"`
//       );
//     }
//
//     this.repetitions = reviewCard.getRepetitions();
//     this.quality = reviewCard.getQuality();
//     this.interval = reviewCard.getInterval();
//     if (reviewCard.getStartat()) {
//       this.startAt = convertTimestampToDate(reviewCard.getStartat()!);
//     }
//     if (reviewCard.getEndat()) {
//       this.endAt = convertTimestampToDate(reviewCard.getEndat()!);
//     }
//   }
// }
