import {BaseService} from "./BaseService";
import {Card} from "model/Card";
import {DTOCreatorRequestType, DTOCreatorResponseType, IGRPCService, ListResponse,} from "model/BaseModel";
import grpcWeb from "grpc-web";
import {
    CardDTO,
    CreateCardRequest,
    DeleteCardRequest,
    GetCardRequest,
    ListCardByIdsRequest,
    ListCardRequest,
    UpdateCardRequest,
} from "proto/card_pb";
import {ListOptionsRequestDTO, UUID_DTO,} from "proto/utils_pb";
import {CardServicePromiseClient} from "proto/card_grpc_web_pb";
import {Err, Ok, Result} from "utils/result";
import {ActionType, isError, IUIError, NewUIErrorV2} from "./cartaError";
import {getUserId} from "./AuthService";
import {CardMediaSignedURL} from "model/CardMedia";
import {CARTA_PROXY_URL} from "consts";
import {unifiedInterceptor} from "../utils/utils";

const cardClient = new CardServicePromiseClient(CARTA_PROXY_URL!, null, {
    withCredentials: true, 	'unaryInterceptors': [unifiedInterceptor]
});

export class CardGRPCImpl
    implements IGRPCService<
        CardDTO,
        DTOCreatorRequestType,
        DTOCreatorResponseType<CardDTO>
    > {
    async listByIDs(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ListResponse<CardDTO> | undefined> {
        let x = await cardClient.listByIds(req as ListCardByIdsRequest, meta);

        return {
            items: x.getItemsList() as CardDTO[],
            info: x.getInfo(),
        } as ListResponse<CardDTO>
    }

    setupListByIDsReq(ids: UUID_DTO[]): DTOCreatorRequestType {
        let req = new ListCardByIdsRequest();

        req.setIdsList(ids);

        return req;
    }

    async create(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<CardDTO | undefined> {
        let x = await cardClient.create(req as CreateCardRequest, meta);
        x.getSignedUrlsList()
        return x.getCard() as CardDTO;
    }

    setupCreateReq(dto: CardDTO): DTOCreatorRequestType {
        let req = new CreateCardRequest();
        req.setCard(dto);

        return req;
    }

    async list(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<ListResponse<CardDTO> | undefined> {
        let x = await cardClient.list(req as ListCardRequest, meta);

        return {
            items: x.getItemsList() as CardDTO[],
            info: x.getInfo(),
        } as ListResponse<CardDTO>
    }

    setupListReq(dto: ListOptionsRequestDTO): DTOCreatorRequestType {
        let req = new ListCardRequest();
        req.setOpts(dto);

        return req;
    }

    async delete(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<void> {
        const x = await cardClient.delete(req as DeleteCardRequest, meta);
        return;
    }

    setupDeleteReq(id: string): DTOCreatorRequestType {
        let req = new DeleteCardRequest();
        req.setCardid(new UUID_DTO().setValue(id));

        return req;
    }

    async get(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<CardDTO | undefined> {
        const x = await cardClient.get(req as GetCardRequest, meta)

        return x.getCard() as CardDTO;
    }

    setupGetReq(id: string): DTOCreatorRequestType {
        let req = new GetCardRequest()
        req.setCardid(new UUID_DTO().setValue(id));

        return req
    }

    setupUpdateReq(dto: CardDTO): DTOCreatorRequestType {
        let req = new UpdateCardRequest();
        req.setCard(dto);

        return req;
    }

    async update(
        req: DTOCreatorRequestType,
        meta?: grpcWeb.Metadata
    ): Promise<CardDTO | undefined> {
        const x = await cardClient.update(req as UpdateCardRequest, meta);
        return x.getCard() as CardDTO;
    }
}

export class CardService extends BaseService<Card, CardDTO, CardGRPCImpl> {
    constructor() {
        super(new CardGRPCImpl(), Card);
    }

    async CreateWithMedia(m: Card): Promise<Result<{ card: Card, signedURLs: CardMediaSignedURL[] }, IUIError>> {
        const actionType = ActionType.Create;

        m.userId = getUserId();
        
        const now = new Date();
        m.createdOn = now;
        m.updatedOn = now;
        
        const dto = m.intoDTO();
        if (isError(dto)) {
            return Err(
                NewUIErrorV2(
                    ActionType.ConvertToDTO,
                    m.TYPE,
                    `failed to convert: ${dto}`
                )
            );
        }

        let req = new CreateCardRequest();
        req.setCard(dto as CardDTO);

        try {
            console.log("dto", dto)
            const resp = await cardClient.create(req as CreateCardRequest);

            const response: CardDTO | undefined = resp.getCard()

            if (response === undefined) {
                return Err(
                    NewUIErrorV2(
                        actionType,
                        m.TYPE,
                        undefined,
                        `created entity response is undefined`
                    )
                );
            }

            const err = m.fromDTO(response);

            // Get URLs
            const urlDTOs = resp.getSignedUrlsList()
            let urls: CardMediaSignedURL[] = []
            urlDTOs.forEach((dto) => {
                let signedURL = new CardMediaSignedURL();
                const err = signedURL.fromDTO(dto);
                
                if (err) {
                    return Err(NewUIErrorV2(actionType, m.TYPE, err as IUIError));
                }

                urls.push(signedURL)
            })

            if (!err) {
                return Ok({card: m, signedURLs: urls});
            } else {
                return Err(
                    NewUIErrorV2(
                        ActionType.ConvertToDTO,
                        m.TYPE,
                        err as IUIError,
                        `failed to convert returned entity: ${JSON.stringify(m)}`
                    )
                );
            }

        } catch (err) {
            return Err(NewUIErrorV2(actionType, m.TYPE, err as IUIError));
        }
    }

    async UpdateWithMedia(m: Card): Promise<Result<{ card: Card, signedURLs: CardMediaSignedURL[] }, IUIError>> {
        const actionType = ActionType.Update;

        m.userId = getUserId();

        const dto = m.intoDTO();
        
        console.log("after 2 dto: ", dto)
        if (isError(dto)) {
            return Err(
                NewUIErrorV2(
                    ActionType.ConvertToDTO,
                    m.TYPE,
                    `failed to convert: ${dto}`
                )
            );
        }

        let req = new UpdateCardRequest();
        req.setCard(dto as CardDTO);

        try {
            const resp = await cardClient.update(req as UpdateCardRequest);

            const response: CardDTO | undefined = resp.getCard()

            if (response === undefined) {
                return Err(
                    NewUIErrorV2(
                        actionType,
                        m.TYPE,
                        undefined,
                        `updated entity response is undefined`
                    )
                );
            }

            const err = m.fromDTO(response);

            // Get URLs
            const urlDTOs = resp.getSignedUrlsList()
            let urls: CardMediaSignedURL[] = []
            urlDTOs.forEach((dto) => {
                let signedURL = new CardMediaSignedURL();
                const err = signedURL.fromDTO(dto);

                if (err) {
                    return Err(NewUIErrorV2(actionType, m.TYPE, err as IUIError));
                }

                urls.push(signedURL)
            })

            if (!err) {
                return Ok({card: m, signedURLs: urls});
            } else {
                return Err(
                    NewUIErrorV2(
                        ActionType.ConvertToDTO,
                        m.TYPE,
                        err as IUIError,
                        `failed to convert returned entity: ${JSON.stringify(m)}`
                    )
                );
            }


        } catch (err) {
            return Err(NewUIErrorV2(actionType, m.TYPE, err as IUIError));
        }
    }
}


