import {TagDTO} from "../proto/tag_pb";
import {ActionType, InternalErrorTypes, isError, IUIError, NewUIError, NewUIErrorV2,} from "../service/cartaError";
import {
	convertDateToTimestamp,
	convertFromDTOToDate,
	convertFromDTOToID,
	generateLightColor,
	IsUUIDValid,
	ListItem
} from "../utils/utils";
import {UUID_DTO} from "../proto/utils_pb";
import {v4 as uuidv4} from "uuid";
import {IDisplayItem, Listable} from "./interfaces";
import {BaseModel, EntityKind, IOwnedModel} from "./BaseModel";
import {Err, Ok, Result} from "utils/result";
import {DEFAULT_TAG_COLOR} from "consts";
import {IFromString} from "./model";

export interface ITag extends IOwnedModel<any>{
	_tag: string,
	_color: string,
	_createdOn: Date,
	_updatedOn: Date,
	_archivedOn?: Date
}

export class Tag extends BaseModel<Tag, TagDTO> implements Listable, IFromString<Tag> {
	tag: string;
	color?: string;
	archivedOn?: Date;
	
	constructor() {
		super();
		
		this.tag = "",
			this.userId = "",
			this.id = uuidv4();
	}
	
	static fromParts(text: string, color?: string): Tag {
		let tag = new Tag();
		tag.tag = text;
		if (color) {
			tag.color = color;
		} else {
			tag.color = generateLightColor();
		}
		
		return tag;
		
	}
	
	fromString(s: string): Result<Tag, IUIError> {
		let tag = new Tag()
		tag.tag = s;
		tag.color = DEFAULT_TAG_COLOR;
		
		const x = tag.customValidate()
		if (isError(x)) {
			return Err(x as IUIError);
		}
		
		return Ok(x as Tag)
	}
	
	toListItem(): ListItem {
		return {
			id: this.id,
			title: this.tag,
			color: this.color,
		}
	}
	
	kind: EntityKind = EntityKind.Tag
	
	static init(userId: string): Tag {
		const now = new Date();
		let tag = new Tag();
		
		tag.createdOn = now;
		tag.updatedOn = now;
		tag.tag = "";
		tag.userId = userId;
		tag.id = uuidv4();
		
		return tag
	}
	
	init(): Tag {
		return new Tag()
	}
	
	toJSON(): ITag {
		return {
			id: this.id,
			userId: this.userId,
			_tag: this.tag,
			_color: this.color ? this.color : DEFAULT_TAG_COLOR,
			_createdOn: this.createdOn,
			_updatedOn: this.updatedOn,
			_archivedOn: this.archivedOn
		}
	}
	
	TYPE: EntityKind = EntityKind.Tag
	
	sanitize(): Tag {
		this.tag = this.tag.trim();
		this.color = this.color ? this.color.trim() : undefined;
		
		return this
	}
	
	customValidate(): Tag | IUIError {
		if (this.tag.length === 0) {
			const errMsg = "tag is empty";
			return NewUIErrorV2(
				ActionType.Validate,
				this.TYPE,
				errMsg, errMsg, errMsg
			)
		}
		
		if (this.color && this.color.length === 0) {
			const errMsg = "tag color is invalid, must be hex e.g. #ffffff"
			return NewUIErrorV2(
				ActionType.Validate,
				this.TYPE,
				errMsg, `tag color is invalid: got: ${this.color}`, errMsg
			)
		}
		
		return this.sanitize()
	}
	
	static validator(tag: Tag): Result<void, IUIError> {
		if (tag.tag.length === 0) {
			const errMsg = "tag is empty";
			return Err<IUIError>(NewUIErrorV2(
				ActionType.Validate,
				tag.TYPE,
				errMsg, errMsg, errMsg
			))
		}
		
		if (tag.color && tag.color.length === 0) {
			const errMsg = "tag color is invalid, must be hex e.g. #ffffff"
			return Err<IUIError>(NewUIErrorV2(
				ActionType.Validate,
				tag.TYPE,
				errMsg, `tag color is invalid: got: ${tag.color}`, errMsg
			))
		}
		
		return Ok(undefined)
	}
	
	static TagTagValidator(item: string): Result<void, IUIError> {
		if (item.length === 0) {
			const errMsg = "tag is empty";
			return Err<IUIError>(NewUIErrorV2(
				ActionType.Validate,
				EntityKind.Tag,
				errMsg, errMsg, errMsg
			))
		}
		if (item.length > 200) {
			const errMsg = "title cannot exceed 200 characters";
			return Err<IUIError>(NewUIErrorV2(
				ActionType.Validate,
				EntityKind.Tag,
				errMsg, errMsg, errMsg
			))
		}
		
		return Ok(undefined)
	}
	
	static TagColorValidator(color: string): Result<void, IUIError> {
		if (color.length === 0) {
			const errMsg = "tag color is invalid, must be hex e.g. #ffffff"
			return Err<IUIError>(NewUIErrorV2(
				ActionType.Validate,
				EntityKind.Tag,
				errMsg, `tag color is invalid: got: ${color}`, errMsg
			))
		}
		if (color.length > 6) {
			const errMsg = "tag color must be valid hex. #ffffff"
			return Err<IUIError>(NewUIErrorV2(
				ActionType.Validate,
				EntityKind.Tag,
				errMsg, `tag color is invalid: got: ${color}`, errMsg
			))
		}
		
		return Ok(undefined)
	}
	
	static Validators: Array<(item: any) => Result<void, IUIError>> = [
		Tag.TagTagValidator,
		Tag.TagColorValidator
	]
	
	static fromJSON(t: ITag): Tag {
		let tag = new Tag();
		
		tag.id = t.id;
		tag.userId = t.userId;
		tag.tag = t._tag;
		tag.color = t._color;
		tag.createdOn = new Date(t._createdOn);
		tag.updatedOn = new Date(t._updatedOn);
		tag.archivedOn = (t._archivedOn) ? new Date(t._archivedOn) : undefined;
		
		if (t._createdOn) {
			tag.createdOn = new Date(t._createdOn);
		} else {
			throw NewUIErrorV2(ActionType.ConvertFromJSON, EntityKind.Tag, "createdOn is required");
		}
		if (t._updatedOn) {
			tag.updatedOn = new Date(t._updatedOn);
		} else {
			throw NewUIErrorV2(ActionType.ConvertFromJSON, EntityKind.Tag, "updatedOn is required");
		}
		if (t._archivedOn) {
			tag.archivedOn = new Date(t._archivedOn);
		}
		
		return tag
	}
	
	clone(): Tag {
		// Create a new instance of Tag
		let newItem = new Tag();
		
		// Deep copy of primitive and object fields
		newItem.id = this.id;
		newItem.userId = this.userId;
		newItem.tag = this.tag;
		newItem.color = this.color;
		
		newItem.createdOn = new Date(this.createdOn.valueOf());
		newItem.updatedOn = new Date(this.updatedOn.valueOf());
		
		newItem.archivedOn = this.archivedOn ? new Date(this.archivedOn.getTime()) : undefined;
		
		return newItem;
	}
	
	public fromDTO(dto: TagDTO): void | IUIError {
		let origin = "fromDTO";
		let errorKind = InternalErrorTypes.InvalidTagDTO;
		
		this.id = convertFromDTOToID('id', this.TYPE, dto.getId());
		this.userId = convertFromDTOToID('userId', this.TYPE, dto.getUserid());
		this.tag = dto.getTag();
		this.color = dto.getColor();
		
		if (!dto.getTag()) {
			return NewUIError(
				origin,
				errorKind,
				`tag string is empty '' - tag: ${dto}"`
			);
		}
		
		this.createdOn = convertFromDTOToDate('createdOn', this.TYPE, dto.getCreatedon())!;
		this.updatedOn = convertFromDTOToDate('updatedOn', this.TYPE, dto.getUpdatedon())!;
		this.archivedOn = convertFromDTOToDate('archivedOn', this.TYPE, dto.getArchivedon(), true);
	}
	
	intoDTO(): TagDTO | IUIError {
		let origin = "intoDTO";
		let errorKind = InternalErrorTypes.InvalidTag;
		if (!IsUUIDValid(this.id)) {
			return NewUIError(origin, errorKind, `id is not valid: ${this.id}`)
		}
		if (!IsUUIDValid(this.userId)) {
			return NewUIError(origin, errorKind, `user_id is not valid: ${this.userId}`)
		}
		
		let dto = new TagDTO();
		dto.setId(new UUID_DTO().setValue(this.id))
		dto.setUserid(new UUID_DTO().setValue(this.userId))
		dto.setTag(this.tag)
		dto.setColor(this.color ? this.color : DEFAULT_TAG_COLOR);
		dto.setCreatedon(convertDateToTimestamp(this.createdOn));
		dto.setUpdatedon(convertDateToTimestamp(this.updatedOn));
		if (this.archivedOn) {
			dto.setArchivedon(convertDateToTimestamp(this.archivedOn));
		} else {
			dto.setArchivedon(undefined);
		}
		
		return dto;
	}
	
	toDisplayable(): IDisplayItem {
		return {
			id: this.id,
			title: this.tag,
			color: this.color,
		}
	}
	
	to1LineString(): String {
		return this.tag
	}
}
