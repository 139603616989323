import {action, makeObservable, observable, ObservableMap, runInAction, toJS,} from "mobx";
import {
    convertDTOToTopicGraph,
    convertTopicRelationDataToDTO,
    convertTopicToDTO,
    Topic,
    TopicStats,
    validateTopic,
} from "model/topic";
import {
    ActionType,
    InternalErrorTypes,
    isError,
    IUIError,
    LogError,
    LogErrorToProvider,
    NewInternalError,
    NewUIError,
    NewUIErrorV2,
    UIErrorV2,
} from "service/cartaError";
import {ListOptionsRequestDTO, UUID_DTO,} from "proto/utils_pb";
import {RelationshipAction, TopicGraph, TopicRelation, TopicRelationshipData,} from "model/graph";
import {
    CreateCardRelationRequest,
    CreateTopicRelationshipRequest,
    CreateTopicRelationshipResponse,
    GetTopicGraphRequest,
    GetTopicGraphResponse,
    ListTopicForCardRequest,
    ListTopicForCardResponse,
    ListTopicRelationshipRequest,
    ListTopicRelationshipResponse,
    RelTopicDSDTO,
    TopicDTO,
    TopicRelationshipEnumDTO,
    TopicRelationshipGraphDTO,
    UpdateTopicRequest,
    UpdateTopicResponse,
} from "proto/topic_pb";
import {Resource} from "model/resource/Resource";
import {Card} from "model/Card";
import {IsUUIDValid, ListItem, NewUUID, sanitizeListOptions, UUIDDTOToID} from "utils/utils";
import {getUserId} from "service/AuthService";
import {cardClient, CardStore} from "./CardStore";
import {IFromDTO} from "model/model";
import {RelTopic, RelTopicDepth} from "model/RelTopic";
import {TopicGRPCImpl, TopicService} from "service/TopicService";
import {ListCardsForTopicRequest, ListCardsForTopicResponse} from "proto/card_pb";
import {Err, Ok, Result} from "utils/result";
import {EntityKind, ValidListable} from "model/BaseModel";
import {DEFAULT_TOPIC_RELATIONSHIP_DEPTH, MAX_TOPIC_RELATIONSHIP_DEPTH} from "consts";
import {statsClient, topicClient} from "./clients";
import BaseStore from "./BaseStore";
import {
    GetTopicGlobalStatsRequest,
    GetTopicStatRequest,
    GetTopicStatResponse,
    StatPerformanceTopicDTO
} from "../proto/stats_pb";


export class StatPerformanceTopic implements IFromDTO<StatPerformanceTopicDTO>, ValidListable {
    public topic: Topic
    public totalReviewCount: number
    public totalManualReviewCount: number
    public totalSM2ReviewCount: number
    public avgConfidence: number
    
    constructor() {
        this.topic = new Topic()
        this.totalReviewCount = 0
        this.totalManualReviewCount = 0
        this.totalSM2ReviewCount = 0
        this.avgConfidence = 0
        this.id = NewUUID()
    }
    
    id: string;
    
    toListItem(): ListItem {
        return {
            id: this.topic.id,
            title: this.topic.topic,
            metadata1: `${this.totalReviewCount}`,
            metadata2: `${this.totalManualReviewCount}`,
            metadata3: `${this.totalSM2ReviewCount}`,
            metadata4: `${this.avgConfidence}`,
        } as ListItem
    }
    
    fromDTO(t: StatPerformanceTopicDTO): void | IUIError {
        this.topic.fromDTO(t.getTopic()!)
        this.totalReviewCount = t.getTotalreviewcount()
        this.totalManualReviewCount = t.getTotalmanualreviewcount()
        this.totalSM2ReviewCount = t.getTotalsm2reviewcount()
        this.avgConfidence = t.getAvgconfidence()
        
    }
}

interface TopicGlobalStats {
    mostReviewed: StatPerformanceTopic[]
    strongest: StatPerformanceTopic[]
    weakest: StatPerformanceTopic[]
}

export class TopicStore extends BaseStore<Topic, TopicDTO, TopicGRPCImpl, TopicService> {
    // public topics: Topic[] = [];
    // topic - Resource[]
    // @observable
    public topicCardMap: ObservableMap<string, Card[]> = new ObservableMap<
        string,
        Card[]
    >();
    // public topicMap: ObservableMap<string, Topic> = new ObservableMap<
    // 	string,
    // 	Topic
    // 	>();
    // @observable
    public resourcesMap: ObservableMap<string, Resource[]> = new ObservableMap<
        string,
        Resource[]
    >();
    // @observable
    public statsMap: ObservableMap<string, TopicStats> = new ObservableMap<
        string,
        TopicStats
    >();
    // @observable
    public selectedTopic: Topic | null = null;
    // @observable
    public selectedTopicRelations: TopicRelationshipData | null = null;
    // @observable
    public relations: TopicRelationshipData = new TopicRelationshipData();
    public service: TopicService;
    cardStore: CardStore;
    
    // @observable
    public mostReviewed: StatPerformanceTopic[] = [];
    // @observable
    public strongest: StatPerformanceTopic[] = [];
    // @observable
    public weakest: StatPerformanceTopic[] = [];
    
    constructor(service: TopicService, cardStore: CardStore, graph?: TopicRelationshipData) {
        super(service, Topic);
        
        // makeAutoObservable(this)
        
        makeObservable(this, {
            // topics: observable,
            relations: observable,
            selectedTopic: observable,
            statsMap: observable,
            topicCardMap: observable,
            resourcesMap: observable,
            mostReviewed: observable,
            strongest: observable,
            weakest: observable,
            // getGraph: computed,

            getTopicGraph: action,
            createTopicRelationship: action,
            deleteRelationship: action,
            setSelectedTopic: action,
            getTopicStats: action,
            handleTopicCardChange: action,
        });
        
        this.service = new TopicService();
        this.cardStore = cardStore;
        // this.resourcesMap = new Map<string, Resource[]>();
        // this.statsMap = new Map<string, TopicStats>();
        
        if (graph) {
            this.relations = graph;
        }
    }
    
    public setSelectedTopic = (topic: Topic | null) => {
        runInAction(() => {
            this.selectedTopic = topic;
        });
    };
    
    
    public  SetupTopicRelation(mainTopic: Topic, parentTopics: Topic[], childTopics: Topic[], generics: Topic[]): Result<TopicRelationshipData, IUIError> {
        let graph: Map<string, TopicRelation[]> = new Map<string, TopicRelation[]>();
        let topicsMap: Map<string, Topic> = new Map();
        let topics: Topic[] = [];
        
        let baseTopic = mainTopic.clone();
        baseTopic.userId = getUserId();
        
        // Set Parent
        if (parentTopics.length > 0) {
            let parent = parentTopics[0].clone()
            parent.userId = getUserId();
            
            graph.set(parent.id, [
                {
                    id: baseTopic.id,
                    relationship: TopicRelationshipEnumDTO.PARENTCHILD,
                },
            ]);
            topics.push(parent);
        }
        // Set Focus Topic
        topics.push(baseTopic);
        
        // Set Children
        const childRelationships = childTopics.map((child) => {
            child.userId = getUserId();
            
            const relation: TopicRelation = {
                id: child.id,
                relationship: TopicRelationshipEnumDTO.PARENTCHILD,
            };
            
            topics.push(child);
            
            return relation;
        });
        graph.set(baseTopic.id, childRelationships);
        
        
        // Set Generics
        const genericRelationships = generics.map((generic) => {
            generic.userId = getUserId();
            topics.push(generic);
            
            const relation: TopicRelation = {
                id: generic.id,
                relationship: TopicRelationshipEnumDTO.GENERIC,
            };
            
            return relation;
        })
        graph.set(baseTopic.id, genericRelationships);
        
        // Load all topics
        topics.forEach((topic) => {
            topicsMap.set(topic.id, topic);
        });
        
        let relationships: TopicRelationshipData = new TopicRelationshipData();
        relationships.fromExisting(topicsMap, graph);
        
        const result = relationships.validate();
        if (!result.ok) {
            return Err(result.error as IUIError);
        }
        
        console.log("relationships", relationships)
        return Ok(relationships)
    };
    
    // //@action
    public getTopicGraph = async (
        limit: number,
    ): Promise<Result<TopicRelationshipData, IUIError>> => {
        let req: GetTopicGraphRequest = new GetTopicGraphRequest();
        req.setOffset(0);
        req.setLimit(limit);
        
        try {
            const response: GetTopicGraphResponse = await topicClient.getTopicGraph(
                req,
            );
            
            const res = convertDTOToTopicGraph(
                response.getGraph() as TopicRelationshipGraphDTO
            );
            
            if (!res.ok) {
                throw NewUIErrorV2(
                    ActionType.GetTopicGraph,
                    EntityKind.Topic,
                    res.error as UIErrorV2,
                    "Failed to parse/convert topic graph",
                );
            }
            
            const graph = res.value as TopicRelationshipData;
            
            runInAction(() => {
                this.relations = graph;
                graph.topics.forEach((topic) => {
                    this.map.set(topic.id, topic);
                });
            });
            
            return Ok(graph);
        } catch (e) {
            return Err(NewUIErrorV2(ActionType.GetTopicGraph, EntityKind.Topic, `failed to create entity: %v ${e}`));
        }
    };
    
    public newTopicFromStr = (topicStr: string): Result<Topic, IUIError> => {
        if (!topicStr) {
            return Err(
                NewUIError(
                    "newTopicFromStr",
                    InternalErrorTypes.CreateTopic,
                    "topic string is empty"
                )
            )
        }
        
        let topic = new Topic();
        topic.userId = getUserId();
        topic.topic = topicStr;
        
        return Ok(topic)
    }
    // //@action
    async GetGlobalStats(): Promise<Result<TopicGlobalStats, IUIError>> {
        let req: GetTopicGlobalStatsRequest = new GetTopicGlobalStatsRequest();
        
        const userId = getUserId()
        if (userId) {
            req.setUserid(new UUID_DTO().setValue(userId))
        } else {
            throw new UIErrorV2(ActionType.GetStats, EntityKind.Stats, "User ID not found")
        }
        
        try {
            let res = await statsClient.getTopicGlobalStats(req)
            
            let mostReviewed: StatPerformanceTopic[] = [];
            let strongest: StatPerformanceTopic[] = [];
            let weakest: StatPerformanceTopic[] = [];
            
            res.getMostreviewedList().forEach((dto) => {
                let stat = new StatPerformanceTopic();
                stat.fromDTO(dto)
                
                mostReviewed.push(stat)
            })
            
            runInAction(() => {
                this.mostReviewed = mostReviewed;
            });
            
            res.getStrongestList().forEach((dto) => {
                let stat = new StatPerformanceTopic();
                stat.fromDTO(dto)
                
                strongest.push(stat)
            })
            
            runInAction(() => {
                this.strongest = strongest;
            });
            
            res.getWeakestList().forEach((dto) => {
                let stat = new StatPerformanceTopic();
                stat.fromDTO(dto)
                
                weakest.push(stat)
            })
            
            runInAction(() => {
                this.weakest = weakest;
            });
            
            return Ok(
                {
                    mostReviewed: mostReviewed,
                    strongest: strongest,
                    weakest: weakest
                }
            )
        } catch (err) {
            return Err(NewUIErrorV2(ActionType.GetStats, EntityKind.Stats, err));
        }
    }
    
    
    /**
     * Create a topic relationship based on the supplied relationships. Ensure all topics are described in the relationship.
     * The backend will create ones that do not exist.
     * @param relationships
     */
    public createTopicRelationship = async (
        relationships: TopicRelationshipData
    ): Promise<void | IUIError> => {
        const topicsArray = Array.from(relationships.topics.entries());
        
        for (const [key, topic] of topicsArray) {
            topic.userId = getUserId();
            const validation: Topic | IUIError = topic.validate();
            
            if (isError(validation)) {
                return validation as IUIError;
            }
            
            // Update the original topic in the relationships
            relationships.topics.set(key, validation as Topic);
        }
        
        let dto = convertTopicRelationDataToDTO(relationships);
        let req = new CreateTopicRelationshipRequest();
        req.setGraph(dto);
        
        try {
            const response: CreateTopicRelationshipResponse =
                await topicClient.createTopicRelationship(req);
            
            if (response.getGraph() === undefined) {
                return NewUIErrorV2(
                    ActionType.Save,
                    EntityKind.RelTopic,
                    `returned topic graph is undefined`
                );
            }
            
            let res = convertDTOToTopicGraph(
                response.getGraph() as TopicRelationshipGraphDTO
            );
            
            if (res.ok) {
                const relationshipData = res.value as TopicRelationshipData;
                
                runInAction(() => {
                    const topicArr = Array.from(relationshipData.topics.values());
                    this.relations.modify(
                        RelationshipAction.UPDATE,
                        Array.from(relationshipData.topics.values()),
                        relationshipData.graph
                    );
                    
                    topicArr.forEach((topic) => {
                        this.map.set(topic.id, topic);
                    });
                });
            } else {
                return NewUIErrorV2(
                    ActionType.Save,
                    EntityKind.RelTopic,
                    res.error as UIErrorV2,
                    `failed to convert topic graph into DTO`,
                    undefined,
                );
            }
        } catch (err) {
            return NewUIErrorV2(
                ActionType.Save,
                EntityKind.RelTopic,
                `[panic]: failed to create topic relationship`
            );
        }
    };
    
    
    public fetchLocalTopicsByIds = (ids: string[]): Topic[] => {
        let topics: Topic[] = [];
        
        ids.forEach((x) => {
            if (this.map.has(x)) {
                topics.push(this.map.get(x)!)
            }
        })
        
        return topics
    }
    
    //@action
    public deleteRelationship = async (
        id1: string,
        id2: string,
        rel: TopicRelationshipEnumDTO
    ): Promise<void | IUIError> => {
        try {
            let res = await this.service.DeleteRelationship(id1, id2, rel);
            
            if (res === null) {
                runInAction(() => {
                    if (this.relations) {
                        let id1Rels = this.relations.graph.get(id1);
                        
                        if (id1Rels) {
                            let index = id1Rels.findIndex(
                                (x) => x.id === id2 && x.relationship === rel
                            );
                            id1Rels.splice(index, 1);
                        }
                    }
                    return;
                });
            }
            
            return res as IUIError;
        } catch (err) {
            return NewUIError(
                "deleteRelationship",
                InternalErrorTypes.ListTopics,
                `failed to delete relationship: ${err}`
            );
        }
    };
    
    //@action
    public updateTopic = async (topic: Topic): Promise<Topic | IUIError> => {
        const errorKind = InternalErrorTypes.UpdateTopic;
        const origin = "updateTopic";
        
        topic.userId = getUserId();
        
        let validation = validateTopic(topic);
        if (isError(validation)) {
            return validation as IUIError;
        }
        
        let dto = convertTopicToDTO(validation as Topic);
        
        let req = new UpdateTopicRequest();
        req.setTopic(dto);
        
        
        try {
            const response: UpdateTopicResponse = await topicClient.update(
                req,
            );
            
            if (response.getTopic() === undefined) {
                return NewUIErrorV2(
                    ActionType.Update,
                    EntityKind.Topic,
                    `updated topic response is undefined`
                );
            }
            
            const topic = new Topic();
            const err = topic.fromDTO(response.getTopic() as TopicDTO);
            
            if (!err) {
                runInAction(() => {
                    this.relations.modify(RelationshipAction.UPDATE, [topic]);
                    this.map.set(topic.id, topic);
                });
                
                return topic;
            }
            return NewUIErrorV2(
                ActionType.Update,
                EntityKind.Topic,
                err as IUIError,
                `failed to convert topic: ${topic}`,
                undefined,
            );
        } catch (err) {
            return NewUIErrorV2(
                ActionType.Update,
                EntityKind.Topic,
                `failed to delete relationship: ${err}`
            );
        }
    };
    
    //@action
    public fetchCardsForTopic = async (
        topicId: string,
        pageLimit: number,
        pageNumber: number,
        searchText?: string
    ): Promise<Result<Card[], IUIError>> => {
        const errorKind = InternalErrorTypes.TopicCardRelation;
        const origin = "fetchCardsForTopic";
        
        let opts: ListOptionsRequestDTO = new ListOptionsRequestDTO();
        opts.setLimit(pageLimit);
        opts.setOffset(pageNumber);
        
        const newOpts = sanitizeListOptions(opts);
        const req = new ListCardsForTopicRequest();
        req.setTopicid(new UUID_DTO().setValue(topicId));
        req.setOpts(newOpts);
        
        try {
            const response: ListCardsForTopicResponse =
                await cardClient.listCardsForTopic(req);
            
            const cardDTOS = response.getCardsList();
            
            if (!cardDTOS) {
                return Err(NewUIErrorV2(
                    ActionType.List,
                    EntityKind.Topic,
                    "returned value undefined",
                    "failed to fetch cards"
                ));
            }
            
            let cards: Card[] = [];
            
            cardDTOS.forEach((dto) => {
                let card = new Card();
                const err = card.fromDTO(dto)
                if (err) {
                    LogErrorToProvider(NewUIErrorV2(ActionType.List, EntityKind.Topic, err as IUIError, "failed to fetch cards"));
                } else {
                    cards.push(card as Card);
                }
            });
            
            runInAction(() => {
                this.topicCardMap.set(topicId, cards);
                
                cards.forEach((card) =>{
                    this.cardStore.map.set(card.id, card)
                })
            });
            
            return Ok(cards);
        } catch (err) {
            let e = UIErrorV2.fromException(err, ActionType.List, EntityKind.Topic, `failed to fetch cards for topic: ${topicId}`)
            return Err(e);
        }
    };
    
    public FetchChildren = async (parentId: string, depth: number): Promise<Result<TopicRelationshipData, UIErrorV2>> => {
        return this.service.FetchChildren(parentId, depth)
    }
    
    // //@action
    public addCardForTopic = async (
        topicId: string,
        card: Card
    ): Promise<Card | IUIError> => {
        const errorKind = InternalErrorTypes.CreateCard;
        const origin = "addCardForTopic";
        
        const parse = toJS(card);
        
        try {
            
            let res = await this.cardStore.createCardWithTopicRelation(
                parse,
                topicId
            );
            
            if (!isError(res)) {
                const card = res as Card;
                
                runInAction(() => {
                    let cardsMap = this.topicCardMap.get(topicId);
                    if (cardsMap) {
                        cardsMap.push(res as Card);
                    }
                });
                return card;
            } else {
                return res as IUIError;
            }
        } catch (err) {
            return NewUIError(
                origin,
                errorKind,
                `store: failed to fetch cards: Err(Value = ${err})`
            );
        }
    };
    
    //@action
    public linkCardToTopic = async (topicId: string, cardId: string) => {
        const errorKind = InternalErrorTypes.TopicCardRelation;
        const origin = "linkCardToTopic";
        
        if (!IsUUIDValid(topicId)) {
            return NewUIError(
                origin,
                errorKind,
                `supplied topicId: ${topicId} is invalid`
            );
        }
        if (!IsUUIDValid(cardId)) {
            return NewUIError(
                origin,
                errorKind,
                `supplied cardId: ${cardId} is invalid`
            );
        }
        
        try {
            const req = new CreateCardRelationRequest();
            req.setTopicid(new UUID_DTO().setValue(topicId));
            req.setCardid(new UUID_DTO().setValue(cardId));
            
            await topicClient.createCardRelationship(req);
        } catch (err) {
            NewInternalError(
                "addCardForTopic",
                InternalErrorTypes.CreateCard,
                `store: failed to fetch cards: Err(Value = ${err})`
            );
        }
    };
    
    //@action
    public listTopicRelationships = async (
        topicIds: string[], depth?: number
    ): Promise<Result<RelTopicDS, IUIError>> => {
        if (topicIds.length < 1) {
            return Err(NewUIErrorV2(ActionType.List, EntityKind.RelTopic, `no supplied topicIDs`));
        }
        
        const req = new ListTopicRelationshipRequest();
        
        if (depth && depth > MAX_TOPIC_RELATIONSHIP_DEPTH) {
            depth = MAX_TOPIC_RELATIONSHIP_DEPTH
        }
        if (depth === undefined) {
            depth = DEFAULT_TOPIC_RELATIONSHIP_DEPTH
        }
        
        topicIds.forEach((topicId) => {
            if (!IsUUIDValid(topicId)) {
                return Err(NewUIErrorV2(ActionType.List, EntityKind.RelTopic,
                    `supplied topicId: ${topicId} is invalid`
                ));
            }
            let newId = new UUID_DTO().setValue(topicId);
            req.addTopicids(newId);
        });
        req.setDepth(depth)
        
        try {
            let res: ListTopicRelationshipResponse =
                await topicClient.listTopicRelationship(req);
            
            if (!res.getRelationships()) {
                return Err(NewUIErrorV2(ActionType.List, EntityKind.RelTopic,
                    `topic relationships is undefined for topic ${topicIds}`,
                    undefined
                ));
            }
            
            const ds = new RelTopicDS();
            const err = ds.fromDTO(res.getRelationships()!);
            if (err) {
                return Err(NewUIErrorV2(ActionType.ConvertFromDTO, EntityKind.RelTopic,
                    err,
                    undefined
                ));
            }
            
            runInAction(() => {
                ds.topicMap.forEach((topic, key) => {
                    this.map.set(key, topic);
                });
            });
            
            return Ok(ds);
        } catch (err) {
            return Err(NewUIErrorV2(ActionType.List, EntityKind.RelTopic, err, `store: failed to fetch cards`))
        }
    };
    
    //@action
    public getTopicStats = async (
        topicId: string
    ): Promise<TopicStats | IUIError> => {
        if (!IsUUIDValid(topicId)) {
            return NewUIError(
                "getTopicStats",
                InternalErrorTypes.CardTagRelation,
                `supplied topicId: ${topicId} is invalid`
            );
        }
        
        let req: GetTopicStatRequest = new GetTopicStatRequest();
        req.setTopicid(new UUID_DTO().setValue(topicId));
        
        
        try {
            const response: GetTopicStatResponse = await statsClient.getTopicStats(
                req,
            );
            
            if (!response.getStats()) {
                return NewUIError(
                    "GetTopicStats",
                    InternalErrorTypes.GetTopicStat,
                    `topic stat is undefined for topic ${topicId}`,
                    undefined
                );
            }
            let stats = new TopicStats();
            stats.fromDTO(response.getStats()!);
            
            runInAction(() => {
                if (this.relations) {
                    this.statsMap.set(topicId, stats);
                }
            });
            
            return stats;
        } catch (err) {
            return NewUIErrorV2(
                ActionType.Delete,
                EntityKind.Topic,
                `failed to delete relationship: ${err}`
            );
        }
    };
    
    //@action
    public listTopicsForCard = async (
        card_id: string
    ): Promise<Topic[] | IUIError> => {
        const errorKind = InternalErrorTypes.ListTopicsForCard;
        const origin = "listTopicsForCard";
        
        let req: ListTopicForCardRequest = new ListTopicForCardRequest();
        
        if (!IsUUIDValid(card_id)) {
            return NewUIError(
                origin,
                errorKind,
                `supplied cardId: ${card_id} is invalid`
            );
        }
        
        try {
            const response: ListTopicForCardResponse = await topicClient.listTopicsForCard(
                req,
            );
            
            let topics: Topic[] = [];
            
            (response.getTopicsList() as TopicDTO[]).forEach((item, index) => {
                const topic = new Topic();
                const err = topic.fromDTO(item);
                
                if (!err) {
                    topics.push(topic as Topic);
                } else {
                    return NewUIErrorV2(
                        ActionType.ListByIDs,
                        EntityKind.Topic,
                        err as IUIError,
                        `failed to convert ${topic}`,
                        undefined,
                    );
                }
            });
            
            runInAction(() => {
                topics.forEach((topic) => {
                    this.map.set(topic.id, topic);
                });
            });
            
            return topics;
        } catch (err) {
            return NewUIError(
                origin,
                errorKind,
                `failed to list topic(s) for card: %v: ${JSON.stringify(err)}`
            );
        }
    };
    
    concatRelationshipData(
        graph: TopicRelationshipData,
        graph2: TopicRelationshipData
    ): TopicRelationshipData {
        let newGraph = new TopicRelationshipData();
        
        const createMapKey = (relation: TopicRelation): string =>
            `${relation.id}+${relation.relationship}`;
        
        ///////////////////// Handle Graph Map ///////////////////
        // Add the first graphs items
        graph.graph.forEach((relations, key) => {
            newGraph.graph.set(key, relations);
        });
        
        // Logic for 2nd graph
        graph2.graph.forEach((relations, key) => {
            // load graph2 and newGraph into map to search for duplicates
            let graphMap: Map<string, TopicRelation> = new Map();
            relations.forEach((relation) => {
                graphMap.set(createMapKey(relation), relation);
            });
            
            newGraph.graph.forEach((relations, key) => {
                relations.forEach((relation) => {
                    graphMap.set(createMapKey(relation), relation);
                });
            });
            
            newGraph.graph.set(key, Array.from(graphMap.values()));
        });
        
        ///////////////////// Handle Topic Map ///////////////////
        graph.topics.forEach((topic, key) => {
            newGraph.topics.set(key, topic);
        });
        
        graph2.topics.forEach((topic, key) => {
            newGraph.topics.set(key, topic);
        });
        
        return newGraph;
    }
    
    populateFromTopics = (topic: string, data: Card[]) => {
        if (!this.topicCardMap.has(topic)) {
            this.topicCardMap.set(topic, data);
        }
    };
    
    public handleTopicCardChange(topicId: string, card: Card) {
        const existingTopic = this.topicCardMap.get(topicId);
        if (existingTopic) {
            const index = existingTopic.findIndex((x) => x.id === card.id);
            if (index > -1) {
                existingTopic[index] = card;
            } else {
                existingTopic.push(card);
            }
        }
    }
    
    // @computed
    get getRelations(): TopicRelationshipData {
        return this.relations;
    }
    
    // @computed
    get topics(): Map<string, Topic> {
        return this.relations.topics;
    }
    
    // @computed
    get getTopicsArray(): Topic[] {
        return Array.from(this.map.values());
    }
    
    // @computed
    get getGraph(): TopicGraph {
        const topicGraph = TopicGraph.new();
        topicGraph.fromRelationshipData(this.relations);
        
        return topicGraph;
    }
}

/**
 * `RelTopicDS` represents the underlying Topic Graph Datastructures containing the root topics, as well as the
 * topics related to the root topics. This is most useful for visualization.
 *
 * The `topicMap` provides a mapping between the ids in the other fields and the topic models themselves.
 */
export class RelTopicDS implements IFromDTO<RelTopicDSDTO> {
    // `_roots` is a list of the root topics, this can be empty, but if you are trying to show a graph or subgraph with a definite
    // root, it is helpful to have this populated
    private _roots: Topic[] = [];
    // _relTopicList is good.
    private _relTopicList: RelTopicDepth[] = [];
    // This relates a topic id to the topic Topic itself
    private _topicMap: Map<string, Topic> = new Map<string, Topic>();
    
    fromDTO(t: RelTopicDSDTO): void | IUIError {
        t.getTopicmapMap().forEach((value: TopicDTO, key: string) => {
            const topic = new Topic();
            const err = topic.fromDTO(value);
            
            if (!err) {
                this._topicMap.set(key, topic);
            } else {
                LogError(
                    "fromDTO",
                    InternalErrorTypes.InvalidTopic,
                    "",
                    err as IUIError
                );
            }
        });
        
        t.getReltopicsList().forEach((x) => {
            let relTopicDepth = new RelTopicDepth();
            
            if (x.getReltopics()) {
                const err = relTopicDepth.fromDTO(x);
                if (err) {
                    LogError("fromDTO", InternalErrorTypes.InvalidTopic, "", err);
                } else {
                    const topic1 = this.topicMap.get(relTopicDepth.relTopic.topic1Id)
                    const topic2 = this.topicMap.get(relTopicDepth.relTopic.topic2Id)
                    if (topic1) {
                        relTopicDepth.relTopic.topic1 = topic1;
                    }
                    if (topic2) {
                        relTopicDepth.relTopic.topic2 = topic2;
                    }
                    // relTopicDepth.setTopics(this._topicMap) // This is how we populated the topic fields with actual topics instead of ids ... may not be necessary
                    this._relTopicList.push(relTopicDepth);
                }
            }
        });
        
        t.getRootidsList().forEach((x) => {
            const id = UUIDDTOToID(x);
            const root = this._topicMap.get(id);
            
            if (root) {
                this._roots.push(root as Topic);
            } else {
                LogError(
                    "fromDTO",
                    InternalErrorTypes.InvalidTopic,
                    `unknown error: failed to get rel topic root: id ${id} from topic map`
                );
            }
        });
    }
    
    public fromTopicRelationshipData(relData: TopicRelationshipData, roots: Topic[]): IUIError | void {
        this._topicMap = relData.topics;
        this._roots = roots
        
        let userId = ""
        
        let relTopicDepths: RelTopicDepth[] = []
        
        Array.from(relData.graph.entries())
            .forEach(([protagonistId, topicRelations]) => {
                let depth = new RelTopicDepth();
                depth.depth = -1;
                depth.parent_id = protagonistId;
                
                
                topicRelations.forEach((supportingActorId) => {
                    let relTopic = new RelTopic();
                    relTopic.topic1Id = protagonistId
                    relTopic.topic2Id = supportingActorId.id
                    relTopic.relationship = supportingActorId.relationship;
                    
                    const protagonist = relData.topics.get(protagonistId);
                    if (!protagonist) {
                        return NewUIError("fromTopicRelationshipData", InternalErrorTypes.InvalidTopicGraph, `protagonist topic ${protagonistId} not found in topic map`)
                    }
                    
                    const supportingActor = relData.topics.get(supportingActorId.id);
                    if (!supportingActor) {
                        return NewUIError("fromTopicRelationshipData", InternalErrorTypes.InvalidTopicGraph, `supporting actor topic ${supportingActorId.id} not found in topic map`)
                    }
                    
                    relTopic.topic1 = protagonist
                    relTopic.topic2 = supportingActor
                    relTopic.userId = userId;
                    depth.relTopic = relTopic;
                    
                    relTopicDepths.push(depth)
                })
            })
        
        this._relTopicList = relTopicDepths;
    }
    
    get roots(): Topic[] {
        return this._roots;
    }
    
    set roots(value: Topic[]) {
        this._roots = value;
    }
    
    get relTopicList(): RelTopicDepth[] {
        return this._relTopicList;
    }
    
    set relTopicList(value: RelTopicDepth[]) {
        this._relTopicList = value;
    }
    
    get topicMap(): Map<string, Topic> {
        return this._topicMap;
    }
    
    set topicMap(value: Map<string, Topic>) {
        this._topicMap = value;
    }
}
