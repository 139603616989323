import {
	EnumReviewCardKindDTO,
	EnumReviewKindDTO,
	ErrorStatusDTO,
	ListOptionsRequestDTO,
	ProgressStateEnumDTO,
	TimestampDTO,
	UUID_DTO,
} from "proto/utils_pb";
import React, {useEffect, useState} from "react";
import {ResourceKind} from "proto/resource_pb";
import {CardDTO} from "proto/card_pb";
import {DateTime, ToRelativeOptions} from "luxon";
import {v4 as uuidv4, validate} from "uuid";
import {useLocation} from "react-router-dom";
import {
	ActionType,
	GrpcError, IGrpcError, IGrpcMetadata,
	InternalErrorTypes,
	IUIError,
	LogErrorToProvider,
	NewUIError,
	NewUIErrorV2,
	UIErrorV2
} from "service/cartaError";
import {ReviewSM2FilterConfigDTO} from "proto/reviewSM2_pb";
import {differenceInSeconds, formatDistanceToNow, formatDuration, intervalToDuration} from "date-fns";
import {Ok, Result} from "utils/result";
import axios, {AxiosResponse} from "axios";
import {EntityKind} from "model/BaseModel";
import {Topic} from "model/topic";
import {TreeNode} from "components/tree/CustomTreeView";
import {TopicRelationshipEnumDTO} from "proto/topic_pb";
import {CardMedia, CardMediaSignedURL} from "model/CardMedia";
import {ObservableMap} from "mobx";
import {
	DEFAULT_LIMIT,
	DEFAULT_SM2_CARD_LIMIT,
	LOCAL_STORAGE_PRICING_SESSION,
	MAX_LIMIT,
	MAX_LIMIT_CARD,
	MAX_LIMIT_RESOURCE,
	MAX_LIMIT_REVIEW,
	MAX_LIMIT_REVIEW_MANUAL,
	MAX_LIMIT_TAG,
	MAX_LIMIT_TOPIC
} from "consts";
import {Metadata, Status, UnaryInterceptor, UnaryResponse} from "grpc-web";
import {PricingSession} from "../pages/billing/pricing";
import {enqueueSnackbar} from "notistack";

export const unifiedInterceptor: UnaryInterceptor<any, any> = {
	async intercept(request, invoker) {
		try {
			const response: UnaryResponse<any, any> = await invoker(request);
			
			const grpcStatus = response.getStatus();
			const metadata = GrpcError.convertMetadataToGrpcMetadata( response.getMetadata());
			
			// Handle error based on gRPC status
			if (grpcStatus.code !== 0) {
				throw new GrpcError(response.getStatus().code, response.getResponseMessage(), metadata);
			}
			
			return response;
		} catch (e) {
			let error: IGrpcError = e as unknown as IGrpcError;
			if (error.metadata) {
				const metadata: IGrpcMetadata = error.metadata;
				
				let meta =  GrpcError.convertJSONToGrpcMetadata(metadata);
				throw new GrpcError(error.code, error.message, meta);
			}
			
			// Re-throw the error with added context
			// LogErrorToProvider(err);
			throw error;
		}
	},
};



export const getPricingSession = (): PricingSession | null => {
	// Retrieve the session data from localStorage
	const sessionData = localStorage.getItem(LOCAL_STORAGE_PRICING_SESSION);
	
	// Parse the session data to an object of type PricingSession if it exists
	let session: PricingSession | null = null;
	if (sessionData) {
		try {
			session = JSON.parse(sessionData) as PricingSession;
		} catch (error) {
			console.error("Failed to parse pricing session data:", error);
			return null
		}
	}
	
	if (session) {
		return session
	} else {
		return null
	}
}

export function unescapeString(encodedString: string): string {
	return decodeURIComponent(encodedString);
}

// export const handleError = (error: IUIError): UIErrorV2 => {
// 	if (error instanceof UIErrorV2) {
// 		LogErrorToProvider(error)
// 		enqueueSnackbar(err.userMessage, {variant: "error"})
// 		return error;
// 	}
//
// 	return NewUIError(ActionType.Unknown, EntityKind.Unknown, "unknown error");
// }

export const formatDataTestIdName = (pageName: string, elementName: string): string => {
	return `${pageName}-${elementName}`;
}

/**
 * Replaces placeholders {:v0}, {:v1}, ..., {:vN} in a string with values from a supplied array.
 *
 * @param str - The input string containing placeholders like {:v0}, {:v1}, etc.
 * @param replacerArray - An array of replacement values.
 * @returns The string with placeholders replaced by their corresponding values.
 */
export function replacePlaceholders(str: string, replacerArray: string[]): string {
	return replacerArray.reduce((result, value, index) => {
		const placeholder = `{:v${index}}`;
		return result.replace(placeholder, value);
	}, str);
}


// This represents an Item that can be displayed. It must have a unique id identifier and a title to be displayed.
export interface SimpleDisplayItem {
	id: string;
	title: string;
	color?: string;
}

export function isErrorStatusDto(metadata: any): metadata is ErrorStatusDTO {
	return (
		typeof metadata === 'object' &&
		metadata !== null &&
		'code' in metadata &&
		'message' in metadata &&
		typeof metadata.code === 'string' &&
		typeof metadata.message === 'string'
	);
}

export function generateLightColor(): string {
	// Generating a random hue between 0 and 360
	const hue = Math.floor(Math.random() * 360);
	// Keeping saturation high for vibrancy, let's say 80%
	const saturation = 80;
	// Setting lightness above 70% to ensure the color is light enough
	const lightness = 70 + Math.random() * 30; // random lightness between 70% and 100%
	
	// Returning the HSL color string
	return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

// This represents an Item that can be displayed. It must have a unique id identifier and a title to be displayed.
export interface DisplayItem {
	id: string;
	title: string;
	// This is used primarily for tooltips
	description?: string;
	// The text field is a generic field for any text one would like to add.
	text?: string;
}

export function hexToRgb(hex: string): string {
	// Remove hash if present
	hex = hex.replace(/^#/, "");
	if (hex.length === 3) {
		hex = hex.split("").map((x) => x + x).join("");
	}
	const bigint = parseInt(hex, 16);
	const r = (bigint >> 16) & 255;
	const g = (bigint >> 8) & 255;
	const b = bigint & 255;
	return `${r}, ${g}, ${b}`;
}

export const uploadFile = async (signedURL: string, file: File): Promise<Result<AxiosResponse, IUIError>> => {
	if (!file) throw NewUIErrorV2(ActionType.MediaUpload, EntityKind.CardMediaSignedUrl, undefined, "file is undefined");
	
	const response = await axios.put(signedURL, file, {
		headers: {
			'Content-Type': file.type,
		},
	});
	return Ok(response);
};

export function MapInsertAtHead<K, V>(map: ObservableMap<K, V>, key: K, value: V): ObservableMap<K, V> {
	// Create a new Map with the new key-value pair
	const newMap = new ObservableMap<K, V>([[key, value]]);
	
	// Add all existing entries to the new Map
	for (const [existingKey, existingValue] of map) {
		newMap.set(existingKey, existingValue);
	}
	
	return newMap;
}

export async function handleFileUploads(signedURLs: CardMediaSignedURL[], cardMedia: CardMedia[]): Promise<Result<AxiosResponse, IUIError>> {
	for (const media of cardMedia) {
		let url = signedURLs.find((x) => x.mediaId === media.id);
		
		if (url) {
			try {
				await uploadFile(url.signedUrl, media.file);
			} catch (e) {
				throw NewUIErrorV2(ActionType.MediaUpload, EntityKind.CardMediaSignedUrl, e, `unable to upload to s3: type: ${media.file.type} - size: ${media.file.size}`);
			}
		} else {
			throw NewUIErrorV2(ActionType.MediaUpload, EntityKind.CardMediaSignedUrl, undefined, "url returned after create is empty/undefined");
		}
	}
	
	return Ok({} as AxiosResponse);
}

export const generateTopicTree = (topic: Topic, children: Topic[], generics: Topic[], parent?: Topic): [TreeNode[], TreeNode[]] => {
	let genericsNodes: any[] = generics.map((generic) => {
	return {
			id: generic.id,
			name: generic.topic,
			color: generic.color,
			relationship: TopicRelationshipEnumDTO.GENERIC,
		};
	})
	
	let treeNodes = []
	if (parent) {
		treeNodes.push(
			{
				id: parent.id,
				name: parent.topic,
				color: parent.color,
				relationship: TopicRelationshipEnumDTO.PARENTCHILD,
				children: [
					{
						id: topic.id,
						name: topic.topic,
						relationship: TopicRelationshipEnumDTO.PARENTCHILD,
						children: children.map((child) => {
							return {
								id: child.id,
								color: child.color,
								relationship: TopicRelationshipEnumDTO.PARENTCHILD,
								name: child.topic,
							};
						}),
					},
				],
			}
		);
	} else {
		treeNodes.push(
			{
				id: topic.id,
				name: topic.topic,
				color: topic.color,
				relationship: TopicRelationshipEnumDTO.PARENTCHILD,
				children: children.map((child) => {
					return {
						id: child.id,
						color: child.color,
						relationship: TopicRelationshipEnumDTO.PARENTCHILD,
						name: child.topic,
					};
				}),
			},
		)
	}
	// treeNodes.map((node) => {
	// 	node.children.push(...genericsNodes)
	// })
	
	return [treeNodes, genericsNodes];
}

// import { Map as jspbMap } from 'google-protobuf';

// export function getKeysFromJspbMap<K, V>(map: jspb.Map<K, V>): K[] {
//     const keys: K[] = [];
//     map.forEach((_value, key) => {
//         keys.push(key);
//     });
//     return keys;
// }

export function areArraysWeakEqual<T>(arr1: T[], arr2: T[]): boolean {
	if (arr1.length !== arr2.length) {
		return false;
	}
	
	// Sort both arrays and check if every element is equal
	const sortedArr1 = [...arr1].sort();
	const sortedArr2 = [...arr2].sort();
	
	return sortedArr1.every((value, index) => value === sortedArr2[index]);
}

export const StringArrToLine = (items: string[]) => items.join(", ")
export const ValidateText = (input: string): boolean => {
	const allowedCharactersRegex = /^[a-zA-Z0-9!@#$%^&*()\{\}\[\]'":?. ]+$/;
	return allowedCharactersRegex.test(input);
};

export const NewUUID = (): string => {
	return uuidv4();
};

export const NewDefaultUUID = (): string => {
	return "00000000-0000-0000-0000-000000000000"
};

export const IsUUIDValid = (id: string): boolean => {
	return validate(id);
};

export const StringToUUID = (id: string): UUID_DTO | IUIError => {
	if (IsUUIDValid(id)) {
		let uuid = new UUID_DTO();
		uuid.setValue(id);
		return uuid;
	}
	
	return NewUIError("StringToUUID", InternalErrorTypes.InvalidUUID, "Invalid UUID");
}

export interface ResourceKindObj {
	title: string;
	kind: ResourceKind;
}

export interface ProgressStateObj {
	title: string;
	kind: ProgressStateEnumDTO;
}

export function useQuery() {
	const {search} = useLocation();
	
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const ProgressStateMap: Map<ProgressStateEnumDTO, string> = new Map<
	ProgressStateEnumDTO,
	string
>([
	[ProgressStateEnumDTO.IN_PROGRESS, "In Progress"],
	[ProgressStateEnumDTO.COMPLETE, "Complete"],
	[ProgressStateEnumDTO.NOT_STARTED, "Not Started"],
]);

export const ReviewKindMap: Map<EnumReviewKindDTO, string> = new Map<
	EnumReviewKindDTO,
	string
>([
	[EnumReviewKindDTO.MANUAL, "Manual"],
	[EnumReviewKindDTO.SM2, "SM2"],
]);


export interface FetchOpts {
	invalidate?: boolean;
	limit?: number;
	offset?: number;
}


// This interface allos implementors to define sensible defaults for the fetch options.
export interface IFetchOpts {
	parseDefaultFetchOpts(opts?: FetchOpts): FetchOpts;
}

export const ParseDefaultFetchOpts = (opts?: FetchOpts): FetchOpts => {
	if (opts === undefined) {
		return {
			invalidate: false,
			limit: DEFAULT_LIMIT,
			offset: 0
		};
	}
	
	let invalidate = (opts!.invalidate) ? true : false;
	let limit = (opts!.limit) ? opts!.limit : DEFAULT_LIMIT;
	let offset = (opts!.offset) ? opts!.offset : 0;
	
	return {
		invalidate: invalidate,
		limit: limit,
		offset: offset
	}
}

/**
 * Computes the hash of a given file using SHA-256.
 * @param file - The file to compute the hash for.
 * @returns A promise that resolves to the file's hash as a hexadecimal string.
 */
export const computeFileHash = async (file: File): Promise<string> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		
		reader.onload = async () => {
			try {
				const arrayBuffer = reader.result as ArrayBuffer;
				const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
				const hashArray = Array.from(new Uint8Array(hashBuffer));
				const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
				resolve(hashHex);
			} catch (error) {
				reject(error);
			}
		};
		
		reader.onerror = () => {
			reject(new Error('Error reading file'));
		};
		
		reader.readAsArrayBuffer(file);
	});
};

const generateHash = async (file: Blob): Promise<string> => {
	const arrayBuffer = await file.arrayBuffer();
	const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
	return Array.from(new Uint8Array(hashBuffer)).map(b => b.toString(16).padStart(2, '0')).join('');
};

// /**
//  * This custom hook is a wrapper around `useSearchParams()` that parses and
//  * serializes the search param value using the JSURL library, which permits any
//  * JavaScript value to be safely URL-encoded.
//  *
//  * It's a good example of how React hooks offer a great deal of flexibility when
//  * you compose them together!
//  *
//  * TODO: rethink the generic type here, users can put whatever they want in the
//  * URL, probably best to use runtime validation with a type predicate:
//  * https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
//  */
// export function useQueryParam<T>(
//     key: string
// ): [T | undefined, (newQuery: T, options?: NavigateOptions) => void] {
//   let [searchParams, setSearchParams] = useSearchParams();
//   let paramValue = searchParams.get(key);
//
//   let value = React.useMemo(() => JSURL.parse(paramValue), [paramValue]);
//
//   let setValue = React.useCallback(
//       (newValue: T, options?: NavigateOptions) => {
//         let newSearchParams = new URLSearchParams(searchParams);
//         newSearchParams.set(key, JSURL.stringify(newValue));
//         setSearchParams(newSearchParams, options);
//       },
//       [key, searchParams, setSearchParams]
//   );
//
//   return [value, setValue];
// }

// interface ListOption {
//     title: string,
//     option: ListOptionsRequestEnumDTO
// }
//
//
// export const ListOptionsMap: Map<ListOptionsRequestEnumDTO, ListOption> = new Map<ListOptionsRequestEnumDTO, ListOption>([
//     [ListOptionsRequestEnumDTO.RANDOM, {title: "Random", option: ListOptionsRequestEnumDTO.RANDOM}],
//     [ListOptionsRequestEnumDTO.MOSTRECENT, {title: "Recent", option: ListOptionsRequestEnumDTO.MOSTRECENT}],
//     [ListOptionsRequestEnumDTO.OLDEST, {title: "Oldest", option: ListOptionsRequestEnumDTO.OLDEST}],
// ])

export const sanitizeListOptions = (
	opts: ListOptionsRequestDTO
): ListOptionsRequestDTO => {
	if (opts.getLimit() > MAX_LIMIT) {
		opts.setLimit(MAX_LIMIT);
	}
	if (opts.getLimit() < 1) {
		opts.setLimit(DEFAULT_LIMIT);
	}
	if (opts.getOffset() < 0) {
		opts.setOffset(0);
	}
	
	return opts;
};

export const DefaultListOptions = (): ListOptionsRequestDTO => {
	let opts = new ListOptionsRequestDTO();
	opts.setLimit(DEFAULT_LIMIT);
	opts.setOffset(0);
	
	return opts;
};

export const IsCardFullyEmpty = (card: CardDTO): boolean => {
	// (card.getFront())
	
	return false;
};

export const Now = (): TimestampDTO => {
	let now = new Date();
	return new TimestampDTO().setSeconds(Math.round(now.getTime()));
};

export const formatDate = (t?: Date): string => {
	if (t) {
		return t.toDateString();
	}
	
	return "";
};


export const containsIllegalChars = (input: string): boolean => {
	const illegalChars = /[^a-zA-Z0-9!@#$%^&*()\{\}\[\]'":?. ]/;
	return illegalChars.test(input);
}

export function validateHumanName(name: string): boolean {
	const validNameRegex = /^[\p{L}\s'-]+(?:\s(?:Jr\.?|Sr\.?|I{1,3}|IV|V|VI|VII|VIII|IX|X))?$/u;
	
	// Test if the name matches the valid pattern
	return validNameRegex.test(name);
}


export const NewReviewSM2FilterConfigDTO = (): ReviewSM2FilterConfigDTO => {
	let config = new ReviewSM2FilterConfigDTO();
	config.setLimit(DEFAULT_SM2_CARD_LIMIT);
	config.setOffset(0);
	config.setId(new UUID_DTO().setValue(NewUUID()));
	config.setNewnessfactor(20)
	config.setCardkind(EnumReviewCardKindDTO.REVIEWCARDKIND_MULTI)
	
	return config;
}

export const convertDateToTimestamp = (date: Date): TimestampDTO => {
	const seconds = Math.floor(date.getTime() / 1000);
	const nanos = (date.getTime() % 1000) * 1e6;
	
	let timestamp = new TimestampDTO();
	timestamp.setSeconds(seconds);
	timestamp.setNanos(nanos);
	
	return timestamp
};

export const convertTimestampToDate = (t: TimestampDTO): Date => {
	return new Date(t.getSeconds() * 1000 + t.getNanos() / 1e6);
};

export const convertOptTimestampToDate = (t?: TimestampDTO): Date | undefined => {
	if (t) {
		return convertTimestampToDate(t);
	}
	
	return undefined;
}

export interface GRPCError {
	code: number;
	message: string;
}

export const UUIDDTOToID = (id: UUID_DTO): string => id.getValue();


export const formatDateTime = (date: Date): string => {
	const formatter = new Intl.DateTimeFormat('en-US', {
		month: 'long',
		day: 'numeric',
		year: 'numeric',
		hour: 'numeric',
		minute: '2-digit',
		hour12: true,
	});
	
	const formattedDate = formatter.format(date);
	
	// Adding 'th', 'st', 'nd', 'rd' to the day
	const day = date.getDate();
	const dayWithSuffix =
		day +
		(day % 10 === 1 && day !== 11
			? 'st'
			: day % 10 === 2 && day !== 12
				? 'nd'
				: day % 10 === 3 && day !== 13
					? 'rd'
					: 'th');
	
	return formattedDate.replace(/\d{1,2}(?=,)/, dayWithSuffix);
};

// Hook
// T is a generic type for value parameter, our case this will be string
export function useDebounce<T>(value: T, delay: number): T {
	// State and setters for debounced value
	const [debouncedValue, setDebouncedValue] = useState<T>(value);
	useEffect(
		() => {
			// Update debounced value after delay
			const handler = setTimeout(() => {
				setDebouncedValue(value);
			}, delay);
			// Cancel the timeout if value changes (also on delay change or unmount)
			// This is how we prevent debounced value from updating if value is changed ...
			// .. within the delay period. Timeout gets cleared and restarted.
			return () => {
				clearTimeout(handler);
			};
		},
		[value, delay] // Only re-call effect if value or delay changes
	);
	return debouncedValue;
}

function getWindowDimensions(divisor: number = 1) {
	const {innerWidth: width, innerHeight: height} = window;
	return {
		width: width / divisor,
		height: height / divisor,
	};
}

export const commaSeparatedString = (items: string[]): string => {
	let str = String();
	items.forEach((item, index) => {
		if (index != items.length - 1) {
			str = str + `${item},`
		} else {
			str = str + `${item}`
		}
	})
	
	return str.toString()
}

export function useWindowDimensions(divisor: number = 1) {
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions(divisor)
	);
	
	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}
		
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);
	
	return windowDimensions;
}

export function removeItemFromArray<T>(arr: Array<T>, value: T): Array<T> {
	const index = arr.indexOf(value);
	if (index > -1) {
		arr.splice(index, 1);
	}
	return arr;
}

export interface ListItem {
	id: string;
	inputValue?: string;
	title: string;
	imageUrl?: string;
	metadata1?: string;
	metadata2?: string;
	metadata3?: string;
	metadata4?: string;
	color?: string;
}

export const formatTo2Dec = (num: number): number => {
	return parseFloat(num.toFixed(2))
}

export const formatOpts = (
	kind: ModelKind,
	limit?: number,
	offset?: number,
	text?: string
): ListOptionsRequestDTO => {
	let opts: ListOptionsRequestDTO = new ListOptionsRequestDTO();
	
	if (limit === undefined) {
		limit = DEFAULT_LIMIT
	}
	if (limit < 0) {
		limit = DEFAULT_LIMIT;
	}
	if (offset && offset < 0) {
		opts.setOffset(0);
	} else {
		opts.setOffset(0);
	}
	if (text) {
		opts.setSearchtext(text);
	}
	
	switch (kind) {
		case ModelKind.Card:
			if (limit && limit > MAX_LIMIT_CARD) {
				limit = MAX_LIMIT_CARD;
				opts.setLimit(limit);
			} else {
				opts.setLimit(MAX_LIMIT_CARD);
			}
			break;
		
		case ModelKind.Tag:
			if (limit && limit > MAX_LIMIT_TAG) {
				limit = MAX_LIMIT_TAG;
				opts.setLimit(limit);
			} else {
				opts.setLimit(MAX_LIMIT_TAG);
			}
			break;
		case ModelKind.Topic:
			if (limit && limit > MAX_LIMIT_TOPIC) {
				limit = MAX_LIMIT_TOPIC;
				opts.setLimit(limit);
			} else {
				opts.setLimit(MAX_LIMIT_TOPIC);
			}
			break;
		case ModelKind.Resource:
			if (limit && limit > MAX_LIMIT_RESOURCE) {
				limit = MAX_LIMIT_RESOURCE;
				opts.setLimit(limit);
			} else {
				opts.setLimit(MAX_LIMIT_RESOURCE);
			}
			break;
		case ModelKind.ReviewSM2:
			if (limit !== undefined && limit > DEFAULT_SM2_CARD_LIMIT) {
				limit = DEFAULT_SM2_CARD_LIMIT;
				opts.setLimit(limit);
			} else {
				opts.setLimit(limit);
			}
			break;
		case ModelKind.ReviewManual:
			if (limit && limit > MAX_LIMIT_REVIEW_MANUAL) {
				limit = MAX_LIMIT_REVIEW_MANUAL;
				opts.setLimit(limit);
			} else {
				opts.setLimit(MAX_LIMIT_REVIEW_MANUAL);
			}
			break;
		case ModelKind.Review:
			if (limit && limit > MAX_LIMIT_REVIEW) {
				limit = MAX_LIMIT_REVIEW;
				opts.setLimit(limit);
			} else {
				opts.setLimit(MAX_LIMIT_REVIEW);
			}
			break;
	}
	
	return opts;
};

export const dateTimeToRelative = (
	time: Date,
	relativeOpts?: ToRelativeOptions
): string => {
	const resp = DateTime.fromJSDate(time).toRelative(relativeOpts);
	if (resp) {
		return resp;
	}
	
	return time.toISOString();
};


export function setupFormatDuration(startAt: Date, endAt: Date): string {
	const totalSeconds = differenceInSeconds(endAt, startAt);
	const totalMinutes = Math.floor(totalSeconds / 60);
	const seconds = totalSeconds % 60;
	
	const totalHours = Math.floor(totalMinutes / 60);
	const minutes = totalMinutes % 60;
	
	const days = Math.floor(totalHours / 24);
	const hours = totalHours % 24;
	
	const parts: string[] = [];
	
	if (days > 0) parts.push(`${days}d`);
	if (hours > 0) parts.push(`${hours}hr`);
	if (minutes > 0) parts.push(`${minutes}m`);
	if (seconds > 0) parts.push(`${seconds}s`);
	
	return parts.join(' ');
}

function formatDurationFromSeconds(totalSeconds: number): string {
	const totalMinutes = Math.floor(totalSeconds / 60);
	const seconds = totalSeconds % 60;
	
	const totalHours = Math.floor(totalMinutes / 60);
	const minutes = totalMinutes % 60;
	
	const days = Math.floor(totalHours / 24);
	const hours = totalHours % 24;
	
	const parts: string[] = [];
	
	if (days > 0) parts.push(`${days}d`);
	if (hours > 0) parts.push(`${hours}hr`);
	if (minutes > 0) parts.push(`${minutes}m`);
	if (seconds > 0) parts.push(`${seconds}s`);
	
	return parts.join(' ');
}

import { format } from 'date-fns';

import { enUS, enGB } from 'date-fns/locale';

type Region = 'US' | 'GB' | 'EU';

export const formatTime = (date: Date, region: Region = 'US'): string => {
	// Determine the locale and time format based on the region
	const is24HourClock = region === 'GB' || region === 'EU';
	const locale = region === 'US' ? enUS : enGB;
	
	// Format string
	const dateFormat = is24HourClock
		? "MMM do - HH:mm:ss" // 24-hour clock
		: "MMM do - h:mm:ss a"; // 12-hour clock
	
	return format(date, dateFormat, { locale });
};

export function calculateDurationWithDivisor(startAt: Date, endAt: Date, divisor?: number): string {
	const totalSeconds = differenceInSeconds(endAt, startAt);
	
	// If a divisor is provided, divide the total duration
	const averageSeconds = divisor ? Math.floor(totalSeconds / divisor) : totalSeconds;
	
	// Format the duration
	return formatDurationFromSeconds(averageSeconds);
}

export const dateTimeToRelativeV2 = (time: Date): string => {
	return formatDistanceToNow(time, {addSuffix: true});
}

export enum ModelKind {
	Card,
	Tag,
	Topic,
	Resource,
	ReviewSM2,
	ReviewManual,
	Review,
}

export const convertFromDTOToID = (fieldName: string, kind: EntityKind, val: UUID_DTO | undefined): string => {
	const value = val?.getValue();
	if (!value) {
		throw NewUIErrorV2(
			ActionType.ConvertFromDTO,
			kind,
			undefined,
			`${fieldName} - UUID_DTO_ID is empty`
		).toString();
	}
	return value;
};

export const convertFromDTOToString = (fieldName: string, kind: EntityKind, val: string | undefined, canBeEmpty?: boolean): string => {
	if (val) {
		return val
	} else {
		if (canBeEmpty) {
			return ""
		}
		throw NewUIErrorV2(
			ActionType.ConvertFromDTO,
			kind,
			undefined,
			`${fieldName} - text value is empty"`
		);
	}
}

export const convertFromDTOToDate = (fieldName: string, kind: EntityKind, val: TimestampDTO | undefined, canBeEmpty: boolean = false): Date | undefined => {
	if (!val) {
		if (canBeEmpty) {
			return undefined
		} else {
			throw NewUIErrorV2(ActionType.ConvertFromDTO, kind, undefined, `${fieldName} - date is empty`);
		}
	}
	
	return convertTimestampToDate(val)
};

export const convertFromDTOToNumber = (fieldName: string, kind: EntityKind, val: number | undefined, canBeEmpty?: boolean): number | IUIError => {
	if (val) {
		return val
	} else {
		if (canBeEmpty) {
			return 0
		}
		return NewUIErrorV2(
			ActionType.ConvertFromDTO,
			kind,
			undefined,
			`${fieldName} - number value is empty"`
		);
	}
}

export const convertToFloat = (num: number): number => {
	return parseFloat(num.toFixed(2))
}

export const calculateDuration = (startTime?: Date, endTime?: Date): string | undefined => {
	if (startTime && endTime) {
		const durationObj = intervalToDuration({start: startTime, end: endTime});
		return formatDuration(durationObj, {format: ['hours', 'minutes', 'seconds']});
	}
	return undefined;
};